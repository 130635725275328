import countries from "react-intl-tel-input/dist/components/AllCountries"
import { BSelect } from "../BSelect"
import { AsYouType, parsePhoneNumberFromString } from "libphonenumber-js"
import { useEffect, useMemo, useRef, useState } from "react"
import { IOption } from "@/types"
import "./style.css"
import { Controller, useFormContext } from "react-hook-form"
import { IInputProps } from "@/Components/Form/Input"
import { KeyCodes } from "@/constants/KeyCodes"

interface ICountryMeta {
  iso2: string
  dialCode: string
  priority: number
}

type CountryPhone = IOption<string> & { meta: ICountryMeta }
const getFormattedPhone = (input: string) => {
  const parsedData = parsePhoneNumberFromString(input?.trimEnd() ?? "")
  if (parsedData == null) return input?.trimEnd() ?? ""
  const formatter = new AsYouType(parsedData.country)
  return formatter.input(input)
}

interface IPhoneInputProps {
  label: string
  dark?: boolean
  defaultCountry?: string
  firstCountries?: string[]
  error?: string
  value?: string
  onChange: (phoneNumber: string) => void
}
export const PhoneInput = ({
  error,
  firstCountries,
  defaultCountry,
  label,
  dark,
  value,
  onChange,
}: IPhoneInputProps) => {
  const dropdownRef = useRef<HTMLButtonElement | null>()
  const listRef = useRef<HTMLUListElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const allCountries = useMemo(() => {
    const result = countries.getCountries().reduce<Record<string, CountryPhone>>((acc, country) => {
      Object.assign(acc, {
        [country.iso2 ?? ""]: {
          label: `${country.name} +${country.dialCode}`,
          value: `${country.iso2}`,
          meta: {
            iso2: country.iso2,
            dialCode: country.dialCode,
            priority: country.priority ?? 0,
          },
        },
      })
      return acc
    }, {})
    const firsts = firstCountries.map((first) => result[first.toLowerCase()])
    firstCountries.forEach((first) => delete result[first])
    return [...firsts, ...Object.values(result)]
  }, [firstCountries])
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [filteredCountries, setFilteredCountries] = useState<typeof allCountries>(allCountries)
  const [phoneNumber, setPhoneNumber] = useState<string>("")
  const [selectedCountry, setSelectedCountry] = useState<CountryPhone | null>(null)
  useEffect(() => {
    if (defaultCountry != null) {
      const option = allCountries.find((country) => country.value === defaultCountry.toLowerCase())
      setPhoneNumber(`+${option.meta.dialCode}`)
      setSelectedCountry(option)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCountry])
  useEffect(() => {
    if (listRef.current) {
      const dropdownNode = listRef.current as HTMLUListElement
      const highlightedItem = dropdownNode.querySelector(".selected-with-arrow") as HTMLDivElement
      if (highlightedItem) {
        const isBigger = highlightedItem.offsetTop > dropdownNode.clientHeight
        dropdownNode.scrollTop = isBigger
          ? highlightedItem.offsetTop - (dropdownNode.clientHeight - highlightedItem.clientHeight)
          : 0
      }
    }
  }, [selectedIndex])
  useEffect(() => {
    if (getFormattedPhone(value).length > 0) setPhoneNumber(getFormattedPhone(value))
  }, [value])

  const isError = error != null

  return (
    <BSelect
      size="large"
      options={filteredCountries}
      isDark={dark}
      label={label}
      value={selectedCountry}
      onOpen={() => {
        inputRef.current.focus()
      }}
      isError={isError}
      onLoadBtnRef={(ref) => (dropdownRef.current = ref.current)}
      errorText={error}
      renderLabel={(option) => (
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <div className={`iti-flag ${option?.value}`} />
          <input
            ref={inputRef}
            type="text"
            style={{ marginLeft: 0, pointerEvents: "initial" }}
            value={phoneNumber}
            onKeyDown={(e) => {
              const isOpened = dropdownRef.current?.classList.contains("show") ?? false
              if (e.key === KeyCodes.ArrowDown || e.key === KeyCodes.ArrowUp) {
                e.preventDefault()
                const isDown = e.key === KeyCodes.ArrowDown
                setSelectedIndex((old) =>
                  isDown ? Math.min(old + 1, filteredCountries.length - 1) : Math.max(old - 1, 0)
                )
                if (!isOpened) dropdownRef.current?.click()
              }
              if (e.key === KeyCodes.Enter) {
                e.preventDefault()
                setSelectedCountry(filteredCountries[selectedIndex])
                setPhoneNumber(
                  `+${getFormattedPhone(filteredCountries[selectedIndex].meta.dialCode)}`
                )
                document.dispatchEvent(new Event("click"))
              }
            }}
            onInput={(e) => {
              const inputValue = (e.target as HTMLInputElement).value
              onChange?.(inputValue.replace(/ /g, ""))
              if (inputValue.length > 4) document.dispatchEvent(new Event("click"))
              else if (!dropdownRef.current?.classList.contains("show"))
                dropdownRef.current?.click()
              if (inputValue.length === 0) setFilteredCountries(allCountries)
              else
                setFilteredCountries(
                  allCountries.filter((country) =>
                    country.label.toLowerCase().includes(inputValue.toLowerCase())
                  )
                )
              if (!inputValue.startsWith(`+${selectedCountry?.meta.dialCode}`)) {
                setSelectedCountry(
                  allCountries
                    .filter((option) => inputValue.startsWith(`+${option.meta.dialCode}`))
                    .sort((a, b) => a.meta.priority - b.meta.priority)[0] ?? allCountries[0]
                )
              }
              setPhoneNumber(getFormattedPhone(inputValue))
            }}
          />
        </div>
      )}
      onSelect={(option) => {
        if (inputRef.current != null) inputRef.current.focus()
        setPhoneNumber(`+${(option as CountryPhone).meta.dialCode}`)
        setSelectedIndex(-1)
      }}
      listRef={listRef}
      renderOption={(option, index) => (
        <div
          style={{ display: "flex", alignItems: "center", gap: 10 }}
          className={`${index === selectedIndex ? "selected-with-arrow" : ""}`}
        >
          <div className={`iti-flag ${option.value}`} />
          {option.label}
        </div>
      )}
    />
  )
}

export const FormPhoneInput = ({
  label,
  rules,
  name,
  required,
  ...props
}: Omit<IInputProps, "isEmail" | "multiline" | "rows" | "onChange"> &
  Omit<IPhoneInputProps, "onChange">) => {
  const { control, errors } = useFormContext()
  if (required) rules.required = { value: true, message: "This field is required" }
  return (
    <Controller
      {...{ control, name, rules }}
      render={({ onChange, value }) => (
        <PhoneInput
          {...props}
          label={label}
          onChange={(value) => onChange(value ?? "")}
          value={value}
          error={errors[name]?.message}
        />
      )}
    />
  )
}
