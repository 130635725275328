import { Image } from "@/Components/common/Image"
import { ArrowButton } from "@/Components/Control/Button"
import { Msg } from "@/Components/Msg"
import { scrollTo } from "@/utils"

export const Cards = () => {
  return (
    <section className="cards">
      <div className="container">
        <div className="cards__wrap">
          <div className="cards__item">
            <Image src="cards-black.png" />
            <div className="cards__box">
              <h3 className="cards__title">
                <Msg id="physical" />
              </h3>
              <div className="cards__slogan">
                <Msg id="card12" />
              </div>
            </div>
            <ul className="cards-list">
              <li className="cards-list__item">
                <Msg id="card_li1" />
              </li>
              <li className="cards-list__item">
                <Msg id="card_li2" />
              </li>
              <li className="cards-list__item">
                <Msg id="card_li3" />
              </li>
              <li className="cards-list__item">
                <Msg id="card_li4" />
              </li>
              <li className="cards-list__item">
                <Msg id="card_li5" />
              </li>
              <li className="cards-list__item">
                <Msg id="card_li6" />
              </li>
            </ul>
            <ArrowButton title={<Msg id="getacard" />} to="#" onClick={() => scrollTo("sing-up")} />
          </div>
          <div className="cards__item">
            <Image src="cards-purpl.png" />
            <div className="cards__box">
              <h3 className="cards__title">
                <Msg id="virtual" />
              </h3>
              <div className="cards__slogan">
                <Msg id="card13" />
              </div>
            </div>
            <ul className="cards-list">
              <li className="cards-list__item">
                <Msg id="card_li7" />{" "}
              </li>
              <li className="cards-list__item">
                <Msg id="card_li8" />{" "}
              </li>
              <li className="cards-list__item">
                <Msg id="card_li9" />{" "}
              </li>
              <li className="cards-list__item">
                <Msg id="card_li10" />{" "}
              </li>
            </ul>
            <ArrowButton title={<Msg id="getacard" />} to="#" onClick={() => scrollTo("sing-up")} />
          </div>
        </div>
      </div>
    </section>
  )
}
