import React, { FC, SelectHTMLAttributes } from "react"
import { useFormContext } from "react-hook-form"

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options: any[]
  ref?: any
  emptyHint?: string
}

const getOptions = (options: any[]) => {
  const length = options instanceof Array ? options.length : Object.keys(options).length
  if (length === 0) return
  if (Array.isArray(options)) {
    if (typeof options[0] === "string") {
      return options.map((opt: string, idx) => (
        <option key={idx} value={opt}>
          {opt}
        </option>
      ))
    }
    return options.map((opt: { value: string | number; label: string | number }, idx) => (
      <option key={idx} value={opt.value}>
        {opt.label}
      </option>
    ))
  }
  return Object.keys(options).map((key, idx) => (
    <option key={idx} value={key}>
      {options[key]}
    </option>
  ))
}

export const CtrlSelect: FC<SelectProps> = ({ options, ...rest }) => {
  const { register } = useFormContext()
  return (
    <select ref={register} {...rest}>
      {getOptions(options)}
    </select>
  )
}
export const Select: FC<SelectProps> = ({ options, emptyHint, ...rest }) => {
  const length = options instanceof Array ? options.length : Object.keys(options).length
  return (
    <select
      {...rest}
      disabled={length === 0}
      className={`${rest.className} ${length === 0 ? "emptyHint" : ""}`}
    >
      <>
        {emptyHint != null && length === 0 && (
          <option className="emptyHint" value="" disabled selected hidden>
            {emptyHint}
          </option>
        )}
        {getOptions(options)}
      </>
    </select>
  )
}
