import React, { FunctionComponent } from "react"
import { Modal } from "@/Components/Modal"
import { Button } from "@/Components/Control/Button"

interface props {
  isShow: boolean
  email: string
  handleClose: () => void
}

export const VerifyModal: FunctionComponent<props> = ({ isShow, email, handleClose }) => {
  const emailDomain = email.split("@")[1] ?? email
  return (
    <Modal size={1} isOpen={isShow} handleClose={handleClose}>
      <div className="Modal__Title">We&apos;ve sent you a verification e-mail</div>
      <div className="Modal__Description">
        E-mail with the confirmation URL was sent to <a href={`mailto:${email}`}>{email}</a>. Visit
        the URL to be redirected back to Wizarbit.
      </div>
      <div className="Modal__Button">
        <a href={`//${emailDomain}`}>
          <Button style={{ padding: "0 12px !important", minWidth: "100%" }}>
            Confirm your e-mail, if you wish to continue
          </Button>
        </a>
      </div>
    </Modal>
  )
}
