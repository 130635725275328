import { FaqBackButton } from "@/Components/Control/Button"
import { Image } from "@/Components/common/Image"
import { Layout } from "@/Layout"
import { Container } from "@/Layout/Container"
import { RelatedBlogs } from "./RelatedBlogs"
import { useParams } from "react-router-dom"
import { useMemo } from "react"
import { blogs } from "./blogs"

export const BlogContent = () => {
  const { blog_id } = useParams<{ blog_id: string }>()
  const blog = useMemo(() => {
    return blogs.find((blog) => blog.id === blog_id)
  }, [blog_id])

  return (
    <Layout>
      <Container>
        <div className="blog-content">
          <FaqBackButton title="Back" to="/blog" />
          <div className="blog-content__header">
            <Image className="mobile-cover" src={`blog/${blog.cover}.png`} />
            <div className="blog-content__cover">
              <Image className="blog-content__cover-bg" src="blog/cloud.png" />
              <Image className="blog-content__cover-bg" src="blog/abstract.png" />
              <Image className="blog-content__cover-cards" src="blog/two-cards.png" />
              <Image src={`blog/${blog.cover}-large.png`} className="blog-content__cover-main" />
              <span>Top-up, buy, spend, withdraw!</span>
            </div>
            <div className="titlebar">
              <p>{blog.title}</p>
              <span>5 July 2024</span>
            </div>
          </div>
          <div className="blog-content__content">{blog.content}</div>
        </div>
        <RelatedBlogs />
      </Container>
    </Layout>
  )
}
