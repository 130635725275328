import warningIcon from "@/Images/cards_images/warning.svg"
import { getCoinIcon } from "@/Pages/TopUpCard/Steps/ByCrypto/E-CurrrencyItem"
import { TableFilterInput } from "@/Pages/TopUpCard/Steps/ByCrypto/TableFilter"
import { isWidgetPage } from "@/utils"
import { ECardType, useCurrencyTable, useTUCardStore } from "@usher/pe-client-front-end-lib"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

const twoWeeksMillis = 14 * 24 * 60 * 60 * 1000
const nextDate = new Date(Date.now() + twoWeeksMillis)
const nextDay = nextDate.getDate() < 10 ? `0${nextDate.getDate()}` : nextDate.getDate()
const nextMonth =
  nextDate.getMonth() + 1 < 10 ? `0${nextDate.getMonth() + 1}` : nextDate.getMonth() + 1
const formattedDate = `${nextDay}/${nextMonth}/${nextDate.getFullYear()}`

export const SelectPayment = () => {
  const history = useHistory()
  const { tableData, amounts } = useCurrencyTable(true)
  const [filterValue, setFilterValue] = useState("")
  const { setCrypto, setDeposit, setFiat, cardType } = useTUCardStore((state) => ({
    setCrypto: state.setCrypto,
    setFiat: state.setFiat,
    setDeposit: state.setDeposit,
    cardType: state.cardTypeId,
  }))
  const [filtered, setFiltered] = useState<typeof tableData>(tableData)
  useEffect(() => {
    if (filterValue.length > 0)
      setFiltered(
        tableData.filter((data) => data.symbol.toLowerCase().startsWith(filterValue.toLowerCase()))
      )
    else setFiltered(tableData)
  }, [filterValue, tableData])
  return (
    <div>
      <div className="block-table-octagon">
        {amounts != null && (
          <div className="warning-info" style={{ marginBottom: 20, fontSize: 14 }}>
            <img src={warningIcon} alt="" />
            <span>
              {`To complete the card issue, please pay ${
                amounts.order_fee
              } euros. In addition, pay a fee of ${amounts.monthly_fee} euros for
          the first month of using the card (total - ${
            amounts.monthly_fee + amounts.order_fee
          } euros)`}
            </span>
          </div>
        )}
        {cardType === String(ECardType.Physical) && (
          <div className="warning-info" style={{ marginBottom: 30, fontSize: 14 }}>
            <img src={warningIcon} alt="" />
            <span>
              Attention! After ordering, the card can be activated and used for online payments, as
              well as added to Apple Pay and Google Pay, but for security reasons we do not
              recommend activating it until you receive it by physical post.{" "}
              <strong>Preliminary card delivery date {formattedDate}</strong>
            </span>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="octagon octagon-whit block-table-octagon">
            <div className="table-octagon_title">
              SELECT PAYMENT CURRENCY
              <TableFilterInput onChange={setFilterValue} />
            </div>
            <div className="table-octagon_content">
              <div className="table-octagon_header">
                <div className="table-octagon_current"></div>
                <div className="table-octagon_amount">Amount</div>
                <div className="table-octagon_completion">Completion</div>
                <div className="table-octagon_spend">You spend</div>
                <div className="table-octagon_btn"></div>
              </div>
              {filtered
                .filter((curr) => curr.symbol !== "BCH")
                .map(({ symbol, ...item }, key) => (
                  <div className="table-octagon_item">
                    <div className="table-octagon_current">
                      <img src={`/img/coins/${getCoinIcon(symbol)}.svg`} alt="" />
                      {symbol}
                    </div>
                    <div className="table-octagon_amount">{Number(item.amount).toFixed(2)} EUR</div>
                    <div className="table-octagon_completion">
                      from 6 Blockchain Network Confirmations
                    </div>
                    <div className="table-octagon_spend">
                      <span>{item.spend}</span> {symbol}
                    </div>
                    <div className="table-octagon_btn">
                      <button
                        className={`btn-reset deposit-btn btn-sq btn-long btn-blue-dark`}
                        onClick={() => {
                          if (amounts != null) {
                            setCrypto(symbol)
                            setDeposit(String(amounts.monthly_fee + amounts.order_fee))
                            setFiat("EUR")
                            history.push(
                              isWidgetPage()
                                ? "/widgets/cards/order-card/instructions"
                                : "/cards/order-card/expense/instructions"
                            )
                          }
                        }}
                      >
                        Pay
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
