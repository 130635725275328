import "./style.css"

interface IStackProps extends React.HTMLAttributes<HTMLElement> {
  direction?: "row" | "column"
  reversable?: boolean
  gap?: number
  mb?: number
}

export const Stack = ({
  direction = "row",
  reversable,
  mb = 38,
  gap = 56,
  ...props
}: IStackProps) => {
  return (
    <div
      {...props}
      className={`stack_container ${reversable ? "reversable" : ""} ${props.className ?? ""}`}
      style={{
        display: "flex",
        marginBottom: mb,
        flexDirection: direction,
        alignItems: "flex-end",
        gap,
        ...props.style,
      }}
    />
  )
}
