import React from "react"
import { NavLink, useRouteMatch } from "react-router-dom"
import { PageTitle } from "@/Components/Layout/PageTitle"
import { useCardList } from "@usher/pe-client-front-end-lib"
import { CardListItem } from "./Item"
import { DashboardInfoBar } from "../../RightSiderbar"
import { TransactionsPager } from "../../Transactions/Pager"
import { PAGE_SIZE } from "../../Transactions"
import { gtag } from "@/Helpers/gTag"

export const CardsList = () => {
  const path = useRouteMatch().path
  const {
    setFilter,
    cards,
    totalRecords,
    filter: { offset },
    pageCount,
    loading,
    isAllExist,
  } = useCardList()
  const isWaitExist = cards.some((card) => card.status.toLowerCase().includes("wait"))
  return (
    <div className="main-content">
      <div className="main-content_content">
        <PageTitle
          title="Cards"
          children={
            !isAllExist &&
            !loading &&
            !isWaitExist && (
              <div className="main-content_btn" onClick={gtag("event", "click_create_card")}>
                <NavLink
                  to={`${path}order-card/expense`}
                  className="btn-reset btn-sq btn-blue no-decorate"
                >
                  Create Card
                </NavLink>
              </div>
            )
          }
        />
        <div className="table-orders">
          <div className="table-orders_header">
            <div className="table-orders_title">
              <strong>Total number of Cards:</strong> {totalRecords}
            </div>
          </div>
          <div className="table-orders_body">
            <div className="table-orders_cards-filter">
              <div className="table-orders_cards-filter_title">
                <span>Masked card number</span>
              </div>
              <div className="table-orders_cards-filter_name">
                <span>Name on card</span>
              </div>
              <div className="table-orders_cards-filter_nickname">
                <span>Card nickname</span>
              </div>
              <div className="table-orders_cards-filter_date">
                <span>Expiry date</span>
              </div>
              <div className="table-orders_cards-filter_type">
                <span>Type</span>
              </div>
              <div className="table-orders_cards-filter_statust">
                <span>Status</span>
              </div>
            </div>
            <div className="table-orders_cards" style={{ maxWidth: "auto !important" }}>
              {!loading && cards?.map((card, key) => <CardListItem key={key} card={card} />)}
            </div>
          </div>
        </div>
        <TransactionsPager
          pageSize={PAGE_SIZE}
          dataTotal={totalRecords}
          pageCount={pageCount}
          pageIndex={offset}
          gotoPage={(page: number) => setFilter(PAGE_SIZE, page)}
          nextPage={() => setFilter(PAGE_SIZE, offset < pageCount ? offset + 1 : offset)}
          previousPage={() => setFilter(PAGE_SIZE, offset > 0 ? offset - 1 : offset)}
          canPreviousPage={offset > 0}
          canNextPage={offset < pageCount - 1}
        />
      </div>
      <DashboardInfoBar />
    </div>
  )
}

export const WidgetView = () => {
  const { cards, totalRecords, loading } = useCardList()
  return (
    <div className="main-content">
      <div className="main-content_content">
        <div className="table-orders">
          <div className="table-orders_header">
            <div className="table-orders_title">
              <strong>Total number of Cards:</strong> {totalRecords}
            </div>
          </div>
          <div className="table-orders_body">
            <div className="table-orders_cards-filter">
              <div className="table-orders_cards-filter_title">
                <span>Card number</span>
              </div>
              <div className="table-orders_cards-filter_name">
                <span>Name on card</span>
              </div>
              <div className="table-orders_cards-filter_nickname">
                <span>Card nickname</span>
              </div>
              <div className="table-orders_cards-filter_date">
                <span>Expiry date</span>
              </div>
              <div className="table-orders_cards-filter_type">
                <span>Type</span>
              </div>
              <div className="table-orders_cards-filter_statust">
                <span>Status</span>
              </div>
            </div>
            <div className="table-orders_cards" style={{ maxWidth: "auto !important" }}>
              {!loading && cards?.map((card, key) => <CardListItem key={key} card={card} />)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
