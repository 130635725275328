import { Button, FaqBackButton } from "@/Components/Control/Button"
import { Text } from "@/Components/Text"
import { ReactComponent as Plus } from "@/Images/icon_files/plus-icon.svg"
import { ReactComponent as Clock } from "@/Images/icon_files/clock-icon.svg"
import "./style.sass"
import { Msg } from "@/Components/Msg"
import { ReactNode } from "react"
import { useHistory } from "react-router-dom"

interface IFaqHeaderProps {
  title?: ReactNode
  back?: string | number
}
export const FaqHeader = ({ title, back }: IFaqHeaderProps) => {
  const history = useHistory()
  return (
    <div className="faq__title">
      {title && <Text variant="paperTitle">{title}</Text>}
      <div className="faq__actions">
        {back != null && <FaqBackButton title={<Msg id="back" />} to={back} />}
        <div>
          <Button startIcon={<Plus />} onClick={() => history.push("/contact-us")}>
            <Msg id="faq_tip1" />
          </Button>
          <Button variant="outlined" startIcon={<Clock />} onClick={() => history.push("/login")}>
            <Msg id="faq_tip2" />
          </Button>
        </div>
      </div>
    </div>
  )
}
