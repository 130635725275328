import { Msg } from "@/Components/Msg"
import { Image } from "@/Components/common/Image"
import { useAvailableCountry } from "@usher/pe-client-front-end-lib"

export const Header = () => {
  const { code, isEnabled, name } = useAvailableCountry()
  console.table({ code, isEnabled, name })
  return (
    <>
      <h1 className="countries__title h2">
        🌎 <Msg id="covered1" />
      </h1>
      {name.length > 0 && (
        <div className="countries__info">
          <Image src={`coutries-${isEnabled ? "check" : "cross"}.svg`} />
          <p className={`countries__text${isEnabled ? " support" : ""}`}>
            <Msg
              id={isEnabled ? "support" : "notSupport"}
              vars={isEnabled ? { country: name } : undefined}
            />
          </p>
        </div>
      )}
    </>
  )
}
