import { Image } from "@/Components/common/Image"
import "./style.css"
import { Layout } from "@/Layout"
import { Button } from "@/Components/Control/Button"
import { AgreeTermCheck } from "../Auth/Register/Checkboxes"
import {
  VALID_EMAIL,
  VALID_NAME,
  VALID_PHONE,
  useContactFormV2,
} from "@usher/pe-client-front-end-lib"
import { FormWrapper } from "@/Components/Form"
import { CtrlReCaptcha } from "@/Components/ctrl/ReCaptcha"
import { Input } from "@/Components/Form/Input"
import { Phones } from "@/Layout/Footer/components/Phones"
import { ThemeMode } from "@/types"
import { COMPANY_EMAIL_ANCHOR } from "@/constants/CommonConstants"
import { FormPhoneInput } from "@/NewComponents/PhoneInput"

export const ContactUs = () => {
  const { onSubmit, formMethods, captchaRef, onCaptchaChange } = useContactFormV2()
  return (
    <Layout>
      <section className="contact">
        <div className="container">
          <div className="contact__wrap">
            <div className="contact__box-title">
              <h1 className="contact-title">Contact Us</h1>
              <div className="contact-subtitle">SUBMIT A TICKET</div>
            </div>
            <div className="contact__content">
              <div className="contact-form">
                <FormWrapper methods={formMethods} onSubmit={onSubmit}>
                  <div className="contact-form__box-label">
                    <Input name="name" required autoFocus rules={VALID_NAME} label="Name" />
                  </div>
                  <div className="contact-form__box-label">
                    <FormPhoneInput
                      label="Phone"
                      firstCountries={["US", "GB"]}
                      defaultCountry="US"
                      name="phone"
                      required
                      rules={{ ...VALID_PHONE, minLength: undefined, maxLength: undefined }}
                    />
                  </div>

                  <div className="contact-form__box-label">
                    <Input name="email" isEmail label="Your email" rules={VALID_EMAIL} required />
                  </div>

                  <div className="contact-form__box-label">
                    <Input name="subjec" label="Subject" required />
                  </div>

                  <div className="contact-form__box-label">
                    <Input multiline rows={7} name="message" label="Message" required />
                  </div>

                  <div className="singup-register">
                    <AgreeTermCheck onlyPrivacy />
                  </div>

                  <div className="captcha">
                    <CtrlReCaptcha refObj={captchaRef as never} onChange={onCaptchaChange} />
                  </div>
                  <Button variant="primary" type="submit">
                    Send
                  </Button>
                </FormWrapper>
              </div>

              <div className="contacts">
                <div className="contacts__box">
                  <h4 className="contacts__title">Contact us</h4>
                  <Phones themeMode={ThemeMode.Dark} />
                  <div className="contacts__email">
                    <span>E-mail</span>
                    {COMPANY_EMAIL_ANCHOR}
                  </div>
                </div>

                <div className="contacts__img">
                  <Image src="planet-cont.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
