import { Image } from "@/Components/common/Image"

interface IAssetRowProps {
  from: number
  label: string
  to: number
  suffix: string
}
export const AssetRow = ({ from, label, to, suffix }: IAssetRowProps) => {
  return (
    <tr>
      <td width="40%">
        <div className="avail-item__box avail-item__box--left">
          <Image className="avail-item__img" src={`${label?.split("-")[0].toLowerCase()}.svg`} />
          <div className="avail-item__block">
            <span>{from}</span>
            <p>{label}</p>
          </div>
        </div>
      </td>
      <td>
        <Image src="leftRight.svg" />
      </td>
      <td width="50%">
        <div className="avail-item__box">
          <Image className="avail-item__img" src={`${suffix?.split("-")[0].toLowerCase()}.svg`} />
          <div className="avail-item__block">
            <span>{to}</span>
            <p>{suffix}</p>
          </div>
        </div>
      </td>
    </tr>
  )
}
