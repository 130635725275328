import { ReactNode, useEffect, useRef } from "react"

interface IClickAwayListener {
  children: ReactNode
  onClickAway: () => void
  className?: string
}

export const ClickAwayListener = ({ children, onClickAway, className }: IClickAwayListener) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const onOutsideClick = (e: MouseEvent) => {
      if (ref.current != null && !ref.current.contains(e.target as HTMLDivElement)) onClickAway()
    }
    if (ref.current != null) document.body.addEventListener("mousedown", onOutsideClick)
    return () => document.body.removeEventListener("mousedown", onOutsideClick)
  }, [ref, onClickAway])

  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  )
}
