import { ArrowButton, Button } from "@/Components/Control/Button"
import "./style.sass"
import { components, useExchangeFormMini, useStoreExchange } from "@usher/pe-client-front-end-lib"
import { ExchangeSelect } from "@/Components/Form/CustomSelect"
import { gtag } from "@/Helpers/gTag"
import shallow from "zustand/shallow"
import { useHistory } from "react-router-dom"
import { useIntl } from "react-intl"
import { Msg } from "@/Components/Msg"

export const ExchangeBlock = ({ isLight }: { isLight?: boolean }) => {
  const intl = useIntl()
  const history = useHistory()
  const [selectFiat, selectCrypto] = useExchangeFormMini()
  const { fetchPrices, amount, crypto, currencytype } = useStoreExchange(
    (state) => ({
      fetchPrices: state.fetchPrices,
      amount: state.fiatAmount,
      crypto: state.cryptoAmount,
      currencytype: state.fiatCurrency,
    }),
    shallow
  )
  return (
    <div className="exchange__block">
      <div className="exchange__fields">
        <ExchangeSelect selector={selectFiat} lightTheme={isLight} />
        <ExchangeSelect selector={selectCrypto} lightTheme={isLight} />
      </div>
      <div className="exchange__block-info">
        <components.Timer
          onUpdate={fetchPrices}
          wrapWithTag=""
          secText="s"
          text={intl.formatMessage({ id: "recalculate" })}
        />
      </div>
      <div
        className="exchange__block-actions"
        style={{ color: `var(--${isLight ? "dark" : "white-2"})` }}
      >
        <Button
          onClick={() => {
            gtag("event", "click_exchange", {
              input_amount: amount,
              input_currency: "EUR",
              output_amount: crypto,
              output_currency: currencytype,
            })()
            history.push("/exchange")
          }}
          minWidth={213}
        >
          <Msg id="exchange" />
        </Button>
        <ArrowButton title={<Msg id="getacard" />} to="/card" />
      </div>
    </div>
  )
}
