import { Image } from "@/Components/common/Image"
import { Msg } from "@/Components/Msg"
import { ThemeMode } from "@/types"
import { Link } from "react-router-dom"
import { Container } from "../Container"
import { Phones } from "./components/Phones"
import { SocialIcons } from "./components/SocialIcons"
import { Title } from "./components/title"
import "./style.css"
import { CSSProperties } from "react"

interface IFooterProps {
  themeMode: ThemeMode
  style?: CSSProperties
}
export const Footer = ({ style, themeMode }: IFooterProps) => {
  const isDark = themeMode === ThemeMode.Dark
  return (
    <footer className={`footer footer-${isDark ? "dark" : "light"}`} style={style}>
      <Container>
        <div className="footer__top">
          <Link to="/" className="footer__logo">
            <Image src={`logo-${isDark ? "light" : "dark"}.svg`} alt="logo" />
          </Link>
          <SocialIcons themeMode={themeMode} />
        </div>
        <div className="footer__info">
          <div className="footer__links">
            <div className="footer__links-cell">
              <Title isDark={isDark} title={<Msg id="footer5" />} />
              <ul className="footer__list-links">
                <li>
                  <Link to="/exchange" className="footer__link">
                    <Msg id="footer1" />
                  </Link>
                </li>
                <li>
                  <Link to="/rates" className="footer__link">
                    <Msg id="footer2" />
                  </Link>
                </li>
                <li>
                  <Link to="/affiliate-program" className="footer__link">
                    Referral Program
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer__links-cell">
              <Title isDark={isDark} title={<Msg id="footer6" />} />
              <ul className="footer__list-links">
                <li>
                  <Link to="/about-us" className="footer__link">
                    <Msg id="footer3" />
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us" className="footer__link">
                    <Msg id="footer4" />
                  </Link>
                </li>
                <li>
                  <Link to="/card" className="footer__link">
                    <Msg id="cards" />
                  </Link>
                </li>

                <li>
                  <Link to="/blog" className="footer__link">
                    Blog
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer__links-cell">
              <Title isDark={isDark} title={<Msg id="footer7" />} />
              <ul className="footer__list-links">
                <li>
                  <Link to="/legal/aml-policy" className="footer__link">
                    <Msg id="amlkyc" />
                  </Link>
                </li>
                <li>
                  <Link to="/legal/terms-of-use" className="footer__link">
                    <Msg id="footer8" />
                  </Link>
                </li>
                <li>
                  <Link to="/legal/privacy-policy" className="footer__link">
                    <Msg id="privacy_title" />
                  </Link>
                </li>
                <li>
                  <Link to="/legal/refund-policy" className="footer__link">
                    <Msg id="refund_title" />
                  </Link>
                </li>
                <li>
                  <Link to="/legal/risk-disclosure" className="footer__link">
                    <Msg id="risk_nav_title" />
                  </Link>
                </li>
                <li>
                  <Link to="/avoid-fraud" className="footer__link">
                    <Msg id="footer10" />
                  </Link>
                </li>
                <li>
                  <Link to="/legal/card-terms" className="footer__link">
                    <Msg id="card_terms_title" />
                  </Link>
                </li>
                <li>
                  <Link to="/legal/card-framework" className="footer__link">
                    <Msg id="card_framework_title" />
                  </Link>
                </li>
                <li>
                  <Link to="/legal/card-use-terms" className="footer__link">
                    <Msg id="card_use_title" />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer__links-cell">
              <Title isDark={isDark} title={<Msg id="help" />} />
              <ul className="footer__list-links">
                <li>
                  <Link to="/faq" className="footer__link">
                    <Msg id="faq" />
                  </Link>
                </li>
                <li>
                  <Link to="/rates-limits" className="footer__link">
                    <Msg id="rates_limits" />
                  </Link>
                </li>
                <li>
                  <Link to="/covered-countries" className="footer__link">
                    <Msg id="covered" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer__aside">
            <div className={`footer__auth${!isDark ? " footer-light__auth-link" : ""}`}>
              <Link
                to="/login"
                className={`footer__auth-link${!isDark ? " footer-light__auth-link" : ""}`}
              >
                <Msg id="login" />
              </Link>
              <span> / </span>
              <Link
                to="/register"
                className={`footer__auth-link${!isDark ? " footer-light__auth-link" : ""}`}
              >
                <Msg id="register2" />
              </Link>
            </div>
            <Phones themeMode={themeMode} />
          </div>
        </div>
        <div className="footer__copy">
          <div className={`footer__copy-left${!isDark ? " footer-light__copy-left" : ""}`}>
            <Image src="credits.svg" />
            <p>
              <Msg id="copyright" /> © {new Date().getFullYear()} WizarBit. <Msg id="all_right" />.
            </p>
          </div>
          <div className={`footer__copy-right ${!isDark ? "footer-light__copy-right" : ""}`}>
            <p>
              <Msg id="uab" vars={{ number: 306098538 }} />
              <br />
              <Msg id="asa" />
              <br />
              <Msg id="address_reg" />: A. Vivulskio g. 7-101, Vilnius, LT-03162, Lithuania
              <br />
              <Msg id="located_at" />: A. Vivulskio g. 7, Vilnius, LT-03162, Lithuania
            </p>
          </div>
        </div>
      </Container>
    </footer>
  )
}
