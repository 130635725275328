import React from "react"
import { useCardActions } from "@usher/pe-client-front-end-lib"
import { useBlockCardMutation, useCloseCardMutation, useUnblockCardMutation } from "@/gql/types"
import { CardActionFreeze } from "./Freeze"
import { CardActionPassword } from "./Password"

export const CardDetailsActions = () => {
  const { isClosed } = useCardActions({
    block: useBlockCardMutation,
    close: useCloseCardMutation,
    unblock: useUnblockCardMutation,
  })
  if (isClosed) return null

  return (
    <div className="main-content_list-btn">
      <CardActionFreeze />
      <CardActionPassword />
      {/* <CardActionClose /> */}
    </div>
  )
}
