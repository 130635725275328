import React, { FunctionComponent } from "react"
import { Modal } from "@/Components/Modal"
import { Button } from "@/Components/Control/Button"

interface props {
  isShow: boolean
  setShowSkip: (state: boolean) => void
  onConfirm: () => void
}
export const SkipModal: FunctionComponent<props> = ({ isShow, setShowSkip, onConfirm }) => {
  return (
    <>
      <span className="document_upload_skip" onClick={() => setShowSkip(true)}>
        SKIP, I'LL DO IT LATER
      </span>
      <Modal size={1} isOpen={isShow} handleClose={() => setShowSkip(false)}>
        <div className="Modal__Title">Verification notice</div>
        <div className="Modal__Description">Verification skipping results in processing delay</div>
        <div className="Modal__Button">
          <Button style={{ minWidth: 272 }} onClick={onConfirm}>
            Ok, I understand
          </Button>
          <Button variant="outlined" onClick={() => setShowSkip(false)}>
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  )
}
