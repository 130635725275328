import { Image } from "@/Components/common/Image"
import "./style.sass"
import { PaymentType } from "@usher/pe-client-front-end-lib"

interface IPaymentTypeSelectProps {
  paymentTypes: PaymentType[]
  selectedPaymentType: PaymentType
  onSelect: (paymentType: PaymentType) => void
}
export const PaymentTypeSelect = ({
  onSelect,
  paymentTypes,
  selectedPaymentType,
}: IPaymentTypeSelectProps) => {
  return (
    <ul className="card-list">
      {paymentTypes.map((type) => (
        <li
          key={type?.id}
          className={`card-list__item${
            type?.name === selectedPaymentType?.name ? " card-list__item-active" : ""
          }`}
        >
          <div onClick={() => onSelect(type)}>
            <Image src={`card-${type?.name}.svg`} alt="Card Master" />
          </div>
        </li>
      ))}
    </ul>
  )
}
