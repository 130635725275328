import React from "react"
import { CardDetailsHeader } from "../Header"
import { CardDetailsActions } from "./Actions"
import { CardDetailsCard } from "./Card"
import { CardDetailsDetails } from "./Details"
import { CardDetailsSensitive } from "./Sensitive"
import { Activation } from "./Activation"
import { ECardType, useStoreCardView } from "@usher/pe-client-front-end-lib"

export const CardDetails = () => {
  const { type, status, id } = useStoreCardView((state) => state.card)
  return (
    <>
      <div className="main-content_one-card">
        <CardDetailsHeader />
        <CardDetailsActions />
        {(status as string).toLowerCase().includes("not") &&
          Number(type) === ECardType.Physical && <Activation card_id={Number(id)} />}
        <div className="block-one-card">
          <CardDetailsCard />
          <div className="block-one-card_info">
            <CardDetailsDetails />
            <CardDetailsSensitive />
          </div>
        </div>
      </div>

      {/* <div className="main-content_one-card_title">Card Limits</div>
      <CardLimits /> */}
    </>
  )
}
