import { CSSProperties, ReactNode } from "react"
import "./index.sass"

export const Description = ({ children }: IWDescriptionProps) => {
  return <div className="Description">{children}</div>
}

interface IWDescriptionProps {
  children: ReactNode
  sx?: Partial<{
    mb: number
    mt: number
  }>
}
export const WDescription = ({ sx, children }: IWDescriptionProps) => {
  const styles: CSSProperties = {}
  if (sx?.mb != null) styles.marginBottom = sx.mb
  if (sx?.mt != null) styles.marginTop = sx.mt
  return (
    <div className="wdescription" style={styles}>
      {children}
    </div>
  )
}
