import React from "react"
import { CardsCreateHeader } from "../Header"

export const CardCreateSalary = () => {
  return (
    <>
      <CardsCreateHeader
        subtitle="Salary card"
        description="VISA card to make external payouts to your employees and affiliates."
      />
      <div className="main-form-cards_form">Coming soon.</div>
    </>
  )
}
