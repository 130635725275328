import React, { useEffect, useRef } from "react"
import { useController, useFormContext } from "react-hook-form"
import { CtrlInput } from "@/Components/ctrl/Input"
import { selectUser, useStoreClient } from "@usher/pe-client-front-end-lib"
import { CountrySelect } from "@/Components/Selector/CoutrySelect"
import classNames from "classnames"
import { isValidPhoneNumber } from "libphonenumber-js"

const Country = () => {
  const { control, errors, setValue, getValues } = useFormContext()
  const {
    field: { ref, ...field },
  } = useController({
    name: "countryCode",
    control,
    rules: {
      required: "This field is required.",
    },
  })

  useEffect(() => {
    if (typeof getValues().countryCode === "string") {
      setValue("countryCode", null)
    }
  }, [getValues, setValue])

  return (
    <div className="mb10">
      <CountrySelect
        {...field}
        searchable
        label="Country"
        value={typeof field.value === "string" ? null : field.value}
        error={errors.countryCode?.message}
      />
    </div>
  )
}

export const Phone = () => {
  const ref = useRef<HTMLInputElement>()
  const { control, errors, register, setValue } = useFormContext()

  const registerField = register({
    required: "This field is required.",
    validate: (v) => (isValidPhoneNumber(v) ? undefined : "Invalid phone number"),
  })

  const { field } = useController({ name: "phoneCode", control })
  const { field: numberField } = useController({ name: "mobile", control })

  useEffect(() => {
    if (field.value == null) {
      setValue("phoneCode", {
        label: "United Kingdom +44",
        meta: { iso2: "gb", dialCode: "44", priority: 0 },
        dialCode: "44",
        iso2: "gb",
        priority: 0,
        value: "gb",
      })
      setValue("mobile", "+44")
    }
  }, [field.value, setValue])

  return (
    <div className="d-flex flex-column mb10">
      <div className="d-flex" style={{ gap: "10px" }}>
        <CountrySelect
          withDialCode
          value={field.value ?? null}
          onlyFlag
          searchable={false}
          onChange={(value) => {
            ref.current?.focus()
            field.onChange(value)
            // @ts-expect-error
            numberField.onChange(value.meta.dialCode != null ? `+${value.meta.dialCode}` : "")
          }}
        />
        <div className="d-flex flex-column" style={{ width: "100%" }}>
          <div
            className={classNames("Form-InputBox", "Order-Card-PIN-Input", `Form-InputBox--Dark`)}
          >
            <input
              className={classNames("Form-Input", `Form-Input--Dark`, {
                [`error`]: errors.mobile,
              })}
              type="tel"
              name="mobile"
              placeholder="Mobile"
              value={numberField.value == null ? "+44" : numberField.value}
              onChange={numberField.onChange}
              ref={(x) => {
                ref.current = x
                registerField(x)
              }}
            />
          </div>
          {errors.mobile && <div className="invalid-feedback">{errors.mobile.message}</div>}
        </div>
      </div>
    </div>
  )
}

export const ExpenseDeliveryAddress = () => {
  const user = useStoreClient(selectUser)
  const { register, watch } = useFormContext()

  const useCompany = watch("use_company")

  useEffect(() => {
    if (useCompany) return
    // @ts-ignore
    $("#country")?.countrySelect({
      excludeCountries: ["US"],
    })
  }, [useCompany])
  return (
    <div className="white-form">
      <label
        className="mb10"
        style={{
          fontSize: "12px",
          lineHeight: "16px",
          fontWeight: 600,
          fontFamily: "ChakraPetch-SemiBold",
        }}
      >
        Delivery
      </label>
      <div
        style={{
          backgroundColor: "#FCF7D1",
          color: "#896e39",
          fontSize: "12px",
          lineHeight: "18px",
          gap: "8px",
          margin: "16px 0",
          display: "flex",
          padding: "15px",
          fontFamily: "ChakraPetch-Regular",
          alignItems: "flex-start",
        }}
        className="p-[15px] bg-[#FCF7D1] flex items-start text-[#896e39] text-[14px] gap-2.5 my-2 !text-[12px]"
      >
        <img src="/img/alert.svg" alt="" />

        <span>
          <strong>Important:</strong> please ensure you provide the correct address when ordering
          your card, as it cannot be changed once the card is ordered.
        </span>
      </div>
      <div
        style={{
          borderBottom: "1px solid #E9E9E9",
          margin: "10px 0",
        }}
      />
      <div>
        <input
          type="checkbox"
          name="use_company"
          id="use_company"
          className="input-checkbox"
          ref={register}
        />
        <label htmlFor="use_company" className="label-checkbox mb15">
          Use data from the users profile
        </label>

        {useCompany && (
          <div className="info-form-block">
            <div className="info-form">
              <div className="info-form-label">First name</div>
              <div className="info-form-value">{user.first_name}</div>
            </div>
            <div className="info-form">
              <div className="info-form-label">Last name</div>
              <div className="info-form-value">{user.last_name}</div>
            </div>
            <div className="info-form">
              <div className="info-form-label">Address</div>
              <div className="info-form-value">{user.address ?? "N/A"}</div>
            </div>
            <div className="info-form">
              <div className="info-form-label">Country</div>
              <div className="info-form-value">{user.country_name}</div>
            </div>
            <div className="info-form">
              <div className="info-form-label">City</div>
              <div className="info-form-value">{user.city}</div>
            </div>
            <div className="info-form">
              <div className="info-form-label">Apt / suite</div>
              <div className="info-form-value">{user.app}</div>
            </div>
            <div className="info-form">
              <div className="info-form-label">Phone number</div>
              <div className="info-form-value">{user.phone}</div>
            </div>
            <div className="info-form">
              <div className="info-form-label">ZIP / Postal code</div>
              <div className="info-form-value">{user.zip}</div>
            </div>
          </div>
        )}
      </div>

      {!useCompany && (
        <div>
          <CtrlInput name="firstName" placeholder="First name" containerClassName="mb10" required />
          <CtrlInput name="lastName" placeholder="Last name" containerClassName="mb10" required />

          <Phone />
          <Country />

          <CtrlInput name="address1" placeholder="Address" containerClassName="mb10" required />
          <CtrlInput name="address2" placeholder="Apt / suite" containerClassName="mb10" required />
          <CtrlInput name="city" placeholder="City" containerClassName="mb10" required />
          <CtrlInput
            name="PostalCode"
            placeholder="ZIP / Postal code"
            containerClassName="mb10"
            required
          />
        </div>
      )}
    </div>
  )
}
