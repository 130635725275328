import React, { PureComponent } from 'react';
import './index.sass';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

export default class Button extends PureComponent {
    buttonProps() {
        const { children, className, color, to, shape, size, ...rest } = this.props;
        // console.log(this.props);

        return {
            className: classnames('Button', className, {
                [`Button--${color}`]: color,
                [`Button--${shape}`]: shape,
                [`Button--${size}`]: size,
            }),
            ...rest,
        };
    }

    renderInner() {
        const { children, icon } = this.props;

        return (
            <>
                {icon && <div className="Button__Icon">{ icon }</div>}
                {children && children }
            </>
        );
    }

    render() {
        const { to } = this.props;
        const props = this.buttonProps();
        if (to) {
            return (
                <Link to={to} {...props}>
                    {this.renderInner()}
                </Link>
            );
        }
        const { type, ...rest } = props;

        return (
            <button type={type || 'button'} {...rest} >
                {this.renderInner()}
            </button>
        );
    }
}
