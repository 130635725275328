import { useCardActivate } from "@usher/pe-client-front-end-lib"
import { Modal } from "@/Components/Layout/Modal"
import { closeModal } from "@/Helpers/ModalHelper"
import warningIcon from "@/Images/cards_images/warning.svg"

const ID = "activate"
export const Activation = ({ card_id }: { card_id: number }) => {
  const { activateCard } = useCardActivate({ card_id, onSuccess: () => closeModal(ID) })
  return (
    <>
      <Modal id={ID} title="Activate Card">
        <div className="warning-info" style={{ marginBottom: 30, marginTop: 10, fontSize: 14 }}>
          <img src={warningIcon} alt="" />
          <span>
            Attention! For security reasons, we do not recommend activating the card until it is
            received by physical post
          </span>
        </div>
        <button onClick={activateCard} className="btn-reset btn-sq btn-long btn-blue">
          Activate Card
        </button>
      </Modal>
      <div
        className="warning-info"
        style={{ marginBottom: 20, fontSize: 14, alignItems: "flex-start" }}
      >
        <img src={warningIcon} alt="" style={{ marginTop: 8 }} />
        <div className="Activate__Card--wrapper">
          <span>
            Attention! Your card is not active, you may activate it by clicking on the button
          </span>
          <button
            className="btn-reset btn-sq btn-long btn-blue"
            onClick={() => {
              // @ts-ignore
              $(`#${ID}`).modal("show")
            }}
            data-bs-target={ID}
            data-bs-toggle="modal"
            style={{ marginLeft: 10, height: 36, lineHeight: "36px" }}
            id={`#${ID}`}
          >
            Activate Card
          </button>
        </div>
      </div>
    </>
  )
}
