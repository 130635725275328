interface IBackButton {
  onClick: () => void
  className?: string
  text?: string
}
export const BackButton = ({ text, className, ...props }: IBackButton) => {
  return (
    <button className={`btn-reset btn-sq btn-go-back ${className}`} {...props}>
      <span>{text ?? "Go Back"}</span>
    </button>
  )
}
