import { Msg } from "@/Components/Msg"
import { gtag } from "@/Helpers/gTag"
import { useExchangeStep4 } from "@usher/pe-client-front-end-lib"
import { useHistory } from "react-router-dom"

export const Payment = () => {
  const { token, form, formEl, iframeSrc } = useExchangeStep4({
    history: useHistory(),
    onContinue: gtag("event", "exchange_step4_p"),
  })

  return (
    <div className="exchange_payment">
      {!token ? (
        <>
          <div className="Box__Title">
            <Msg id="loading" />
          </div>
          <p>
            <Msg id="wait" />
          </p>
        </>
      ) : (
        <>
          <div className="iframe__form" style={{ height: "fit-content" }}>
            <iframe
              title="payment"
              width="100%"
              height={1050}
              frameBorder="0"
              scrolling="NO"
              src={iframeSrc}
            />
          </div>
          <form action={form.url} method="POST" ref={formEl}>
            {form.params &&
              form.params.map((d: { key: string; value: string }) => (
                <input key={d.key} type="hidden" name={d.key} value={d.value} />
              ))}
          </form>
        </>
      )}
    </div>
  )
}
