import { IOption } from "@/types"
import Select, {
  GroupBase,
  PlaceholderProps,
  DropdownIndicatorProps,
  components,
} from "react-select"

import "./style.sass"
import classNames from "classnames"
import { Image } from "@/Components/common/Image"
import { ExchangeSelector, useStoreExchange } from "@usher/pe-client-front-end-lib"
import shallow from "zustand/shallow"
import { useMemo } from "react"
import { BSelect } from "@/NewComponents/BSelect"

export interface ICustomSelectProps {
  name?: string
  label?: string
  onChange?: (option: IOption) => void
  value?: IOption
  error?: boolean
  lightTheme?: boolean
  placeholder?: string
}
export const ExchangeSelect = ({
  placeholder,
  selector,
  lightTheme = false,
  label,
  error,
}: ICustomSelectProps & { selector: ExchangeSelector }) => {
  const { amount, currency, setAmount, setCurrency, currencies } = useStoreExchange(
    selector,
    shallow
  )
  const filteredOptions = useMemo(() => {
    if (currencies == null) return []
    return currencies.filter((curr) => curr !== "USD").map((opt) => ({ label: opt, value: opt }))
  }, [currencies])

  return (
    <div className="custom-select exchange-select">
      {label != null && <span className={classNames({ "error-label": error })}>{label}</span>}
      <div
        className={classNames("custom-select__normal", {
          "custom-select__error": error,
          "custom-select__light": lightTheme,
        })}
      >
        <input
          type="text"
          placeholder={placeholder}
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <BSelect
          centered
          isDark={!lightTheme}
          options={filteredOptions}
          value={filteredOptions.find((option) => option.value === currency)}
          onSelect={(opt) => setCurrency(opt.value)}
        />
      </div>
    </div>
  )
}

interface IFormSelectProps {
  disabled?: boolean
  options: IOption<string>[]
}
export const FormSelect = ({
  error,
  label,
  options,
  disabled,
  value,
  onChange,
}: ICustomSelectProps & IFormSelectProps) => {
  const Placeholder = (
    props: PlaceholderProps<IOption<string>, boolean, GroupBase<IOption<string>>>
  ) => {
    return <components.Placeholder {...props} />
  }

  const DropdownIndicator = (
    props: DropdownIndicatorProps<IOption<string>, boolean, GroupBase<IOption<string>>>
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <Image src="select-arrow-dark.svg" />
      </components.DropdownIndicator>
    )
  }
  return (
    <div className="form-select__custom">
      {label != null && <span className={classNames({ "error-label": error })}>{label}</span>}
      <div className={`border_wrapper${error ? " error" : ""}`}>
        <Select
          className={classNames(
            "SelectSingle",
            "SelectSingle",
            `SelectSingle--${disabled ? "disabled" : ""}`
          )}
          components={{ DropdownIndicator, Placeholder }}
          getOptionValue={(value) => value.value}
          value={value}
          placeholder="Select"
          classNamePrefix="SelectSingle"
          options={options || []}
          isDisabled={disabled}
          onChange={(value) => {
            onChange(value as IOption<string>)
          }}
        />
      </div>
    </div>
  )
}
// export const FormSelect = ({
//   label,
//   options,
//   inputProps,
//   selectProps,
// }: ICustomSelectProps & IFormSelectProps) => {
//   return (
//     <label className="form-select__custom">
//       <span>{label}</span>
//       <input type="text" placeholder="" readOnly {...inputProps} />
//       <select name="#" id="#" {...selectProps}>
//         {options.map((option) => (
//           <option key={option.value} value={option.value}>
//             {option.label}
//           </option>
//         ))}
//       </select>
//     </label>
//   )
// }
