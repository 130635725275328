import { useFormContext, UseControllerOptions } from "react-hook-form"
import { VALID_EMAIL } from "@usher/pe-client-front-end-lib"
import "./style.css"
import classNames from "classnames"

export interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  isEmail?: boolean
  rules?: UseControllerOptions["rules"]
  dark?: boolean
  multiline?: boolean
  rows?: number
}
export const Input = ({
  dark,
  rules = {},
  isEmail,
  label,
  name,
  required,
  multiline,
  rows,
  ...props
}: IInputProps) => {
  const { register, errors } = useFormContext()
  if (required) rules.required = { value: true, message: "This field is required" }
  if (isEmail) Object.assign(rules, { ...VALID_EMAIL })
  const isError = errors[name] != null
  return (
    <>
      <label className={`form-input${dark ? " form-input__dark" : ""}`}>
        <span className={isError ? "error-label" : ""}>{label}</span>
        {multiline ? (
          //@ts-expect-error
          <textarea
            {...props}
            className={classNames({ error_input: isError })}
            name={name}
            ref={register(rules)}
            rows={rows}
          />
        ) : (
          <input
            type="text"
            {...props}
            className={classNames({ error_input: isError })}
            name={name}
            ref={register(rules)}
          />
        )}
        {isError && <span className="error-label">{errors[name].message}</span>}
      </label>
    </>
  )
}
