import { Msg } from "@/Components/Msg"
import { Image } from "@/Components/common/Image"
import { Country } from "@usher/pe-client-front-end-lib/dist/types/CommonTypes"
import { useEffect, useRef, useState } from "react"

const getIcon = (data: Country) => {
  const region = data.region_name.replace(/\s+/g, "_").toLowerCase()
  const country = data.name.replace(/\s+/g, "-").toLowerCase()
  return <Image src={`countries/${region}/${country}.svg`} alt={data.name} />
}

interface ITableItemProps {
  item: Country
}
export const TableItem = ({ item }: ITableItemProps) => {
  const [opened, setOpened] = useState(false)
  const infoBar = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (infoBar.current == null) return
    infoBar.current.style.maxHeight = (opened ? infoBar.current.scrollHeight : 0) + `px`
  }, [opened, infoBar])
  const imgUrl = `covered-${item.is_enabled ? "support" : "cross"}.svg`
  return (
    <div className="table_body" onClick={() => setOpened(!opened)}>
      <div className="desktop-info">
        <div className="country-table__country">
          {getIcon(item)} {item.name}
        </div>
        <div className="country-table__buy">
          <Image src={imgUrl} />
        </div>
        <div className="country-table__sell">
          <Image src={imgUrl} />
        </div>
        <div className="country-table__methods">
          {item.is_enabled ? "Credit Card, Bank Transfer" : ""}
        </div>
        <div className="country-table__issuing">
          {item.is_enabled ? "Debit cards issuing" : <Image src="covered-cross.svg" />}
        </div>

        <div className="collapse-arrow">
          <Image src={`table-${opened ? "btn" : "arrow"}.svg`} />
        </div>
      </div>
      <div className="mobile-info" ref={infoBar}>
        <div className="table__mob-block show">
          <div className="table__mob-box">
            <div className="table__mob-buy">
              <span className="table__mob-span">
                <Msg id="_buy" />
              </span>
              <Image src={imgUrl} />
            </div>
            <div className="table__mob-sell">
              <span className="table__mob-span">
                <Msg id="_sell" />
              </span>
              <Image src={imgUrl} />
            </div>
          </div>
          <div className="table__mob-pay">
            <span className="table__mob-span">
              <Msg id="covered6" />
            </span>
            <p className="countries-table__text">Credit Card, Bank Transfer</p>
          </div>
          <div className="table__mob-deb">
            <span className="table__mob-span">
              <Msg id="covered7" />
            </span>
            <p className="countries-table__text">Coming Soon!</p>
          </div>
        </div>
      </div>
    </div>
  )
}
