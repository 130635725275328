import { WDescription } from "@/Components/Description"
import { Input } from "@/Components/Form/Input"
import { Msg } from "@/Components/Msg"
import { Stack } from "@/Layout/Stack"
import { ExClient } from "@usher/pe-client-front-end-lib"
import { useIntl } from "react-intl"

interface IWalletAddressInputProps {
  hasWallet: boolean
}
export const WalletAddressInput = ({ hasWallet }: IWalletAddressInputProps) => {
  const intl = useIntl()
  if (!ExClient.shop_id && !hasWallet) return null
  return (
    <div>
      <Stack style={{ marginBottom: ExClient.shop_id ? 0 : 35 }}>
        <Input required name="wallet" label={intl.formatMessage({ id: "td5" })} />
      </Stack>
      {ExClient.shop_id && (
        <WDescription sx={{ mb: 40, mt: 25 }}>
          <Msg id="td4" />
        </WDescription>
      )}
    </div>
  )
}
