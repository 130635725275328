export enum ThemeMode {
  Dark,
  Light,
}

export interface IOption<T = string> {
  label: string
  value: T
}

export enum Currencies {
  BTC = "btc",
  ETH = "eth",
  LTC = "ltc",
  USDT = "usdt",
  USDT_TRC20 = "usdt-trc20",
  USDT_ERC20 = "usdt-erc20",
  USDC_ERC20 = "usdc-erc20",
  USDC_BEP20 = "usdc-bep20",
  DASH = "DASH",
  ICP = "ICP",
  BNB = "BNB",
  NUM = "NUM",
}
