import { useMemo } from "react"
import { useLang } from "@/Layout/hooks/lang"
import { BSelect } from "@/NewComponents/BSelect"

const FLAG_PATHS = {
  "lt-LT": "/img/countries/europe/lithuania.svg",
  "en-US": "/img/countries/europe/united-kingdom.svg",
}

interface ILanguageSelectProps {
  isDark?: boolean
}

export const LanguageSelect = ({ isDark = true }: ILanguageSelectProps) => {
  const { locale, langOptions, onLangChange } = useLang()
  const selectedLang = useMemo(
    () => langOptions.find((lang) => lang.value === locale),
    [locale, langOptions]
  )
  return (
    <>
      <BSelect
        buttonStyle={{ minWidth: 150 }}
        isDark={isDark}
        value={selectedLang ?? { label: "English", value: "en" }}
        options={langOptions.filter((option) => option.value !== locale)}
        leftIcon={
          <img
            src={FLAG_PATHS[locale as keyof typeof FLAG_PATHS]}
            style={{ display: "inline", width: 18, height: 18, marginRight: 8 }}
            alt={selectedLang.label}
          />
        }
        onSelect={(value) => onLangChange(value.value)}
        renderOption={(option) => (
          <>
            <img
              src={FLAG_PATHS[option.value as keyof typeof FLAG_PATHS]}
              style={{ display: "inline", width: 18, height: 18, marginRight: 6 }}
              alt={option.label}
            />
            {option.label}
          </>
        )}
      />
    </>
  )
}
