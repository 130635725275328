interface ITableFilterProps {
  onChange: (value: string) => void
}
export const TableFilterInput = ({ onChange }: ITableFilterProps) => {
  return (
    <label className="table-filter">
      <input placeholder="Filter: All currencies" onChange={(e) => onChange(e.target.value)} />
    </label>
  )
}
