import { IOption } from "@/types"
import clsx from "clsx"
import { ClassNamesConfig, GroupBase } from "react-select"

export const AutocompleteConfig = (
  size: "sm" | "md",
  error: boolean
): ClassNamesConfig<IOption, false, GroupBase<IOption>> => ({
  clearIndicator: () => {
    return clsx(size === "sm" ? "!pr-2 !py-0" : "")
  },
  dropdownIndicator: () =>
    clsx("!text-[#98A2B3] !stroke-[1px]", size === "sm" ? "!pr-2 !py-0" : ""),
  placeholder: () => "!font-normal",
  indicatorSeparator: () => "hidden",
  menuList: () => "px-1 !rounded-lg !border-red-500",
  menu: () => "!rounded-none",
  option: () =>
    clsx("!bg-[white] !text-[14px] text-black !font-weight-[400]", size === "sm" ? "!text-xs" : ""),
  menuPortal: () => "!z-[999999]",
  control: () =>
    clsx(
      "!outline-none !shadow-none !min-h-[auto] !rounded-none !border !border-[#c3ced7]",
      size === "sm" ? "h-[34px]" : "h-[40px]",
      error && "!border-red-500"
    ),
})
