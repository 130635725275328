import { Route, Switch } from "react-router-dom"
import { Login } from "./Login"
import "./index.sass"
import { NewPassword } from "./NewPassword"
import { Layout } from "@/Layout"
import { Register } from "./Register"
import { Recovery } from "./Recovery"

export const Auth = () => {
  return (
    <Layout withHeader={true}>
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/register" exact component={Register} />
        <Route path="/new_password" exact component={NewPassword} />
        <Route path="/recovery" exact component={Recovery} />
      </Switch>
    </Layout>
  )
}
