import React from "react"
import { Link } from "react-router-dom"
import { Image } from "@/Components/common/Image"
import { CardsCreateHeader } from "./Header"

export const CardsCreateType = () => {
  return (
    <>
      <CardsCreateHeader subtitle="Please choose what kind of card you want to create:" />
      <div className="main-form-cards_flex">
        <div className="main-form-cards_card">
          <div className="main-form-cards_card_icon">
            <Image src="mfc5.svg" />
          </div>
          <div className="main-form-cards_card_title">
            Expense card <br />
            (for workers and directors)
          </div>
          <div className="main-form-cards_action">
            <div className="main-form-cards_card_description">
              Physical or virtual VISA card
              <p>for your company`s expenses.</p>
            </div>
            <div>
              <Link
                to="/client/cards/order-card/expense"
                className="main-form-cards_btn btn-reset btn-sq btn-long btn-red no-decorate"
              >
                Create
              </Link>
            </div>
          </div>
        </div>
        <div className="main-form-cards_card">
          <div className="main-form-cards_card_icon">
            <Image src="mfc6.svg" />
          </div>
          <div className="main-form-cards_card_title">
            Salary card <br />
            (for freelancers, affiliates)
          </div>
          <div className="main-form-cards_action">
            <div className="main-form-cards_card_description">
              Physical or virtual VISA card to make external
              <p>payouts to your employees and affiliates.</p>
            </div>
            <div>
              <button className="main-form-cards_btn btn-reset btn-sq btn-long btn-dark-grey no-decorate">
                Coming soon
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
