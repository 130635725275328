import React, { ReactNode } from "react"
import { FormProvider, FormProviderProps, FieldValues, UnpackNestedValue } from "react-hook-form"
import "./index.sass"

export const Form = ({ children, title }: { children: ReactNode; title: string }) => {
  return (
    <div className="Form">
      <div className="Form__Box">
        <div className="Form__Title">{title}</div>
        <div className="Form__Content">{children}</div>
      </div>
    </div>
  )
}

interface IFormWrapperProps<T extends object = FieldValues>
  extends Omit<React.FormHTMLAttributes<HTMLFormElement>, "onSubmit"> {
  methods: Omit<FormProviderProps<T>, "children">
  onSubmit: (data: UnpackNestedValue<T>) => void
}

export const FormWrapper = <T extends object = FieldValues>({
  methods,
  onSubmit,
  ...props
}: IFormWrapperProps<T>) => {
  return (
    <FormProvider {...methods}>
      <form noValidate autoComplete="off" {...props} onSubmit={methods.handleSubmit(onSubmit)} />
    </FormProvider>
  )
}
