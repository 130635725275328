import { Layout } from "@/Layout"
import React from "react"

export const NotFound = () => {
  return (
    <Layout headerTheme={"White"} footerTheme={"White"} withHeader={true}>
      <div className="Rates">
        <div className="Container">
          <h1 className="Rates__Title-H1 Title Title--H1">Page Not Found</h1>
        </div>
      </div>
    </Layout>
  )
}
