import ReactDOM from "react-dom"
import "@/Fonts/index.sass"
import "@/style.css"
import { App } from "@/App"
import reportWebVitals from "@/reportWebVitals"
import { Blocks } from "@/Blocks"

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(<App />, document.getElementById("root") as HTMLElement)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
Blocks()
