import { ICardTransactionItem } from "@usher/pe-client-front-end-lib"
import { TableItem } from "./TableItem"
import Button from "@/Components/Button"
import { DownloadIcon } from "@/Images/Icons"
import { TransactionsFilter } from "../../Transactions/Filter"

export const CardTable = ({
  data,
  onDownloadClick,
  downloadLoading,
  setFilter,
}: {
  data: ICardTransactionItem[]
  onDownloadClick?: VoidFunction
  downloadLoading?: boolean
  setFilter: any
}) => {
  return (
    <div className="Transactions">
      <div className="table-orders" style={{ overflow: "visible" }}>
        {setFilter != null && (
          <div style={{ paddingTop: 20 }}>
            <TransactionsFilter
              widgetView
              compact
              type="Card"
              onFilter={setFilter}
              withoutExchangeTo
            />
          </div>
        )}
        <div className="table-orders_header">
          <div className="table-orders_title">Type Transaction: Card</div>

          <Button
            icon={
              <div style={{ marginRight: "8px" }}>
                <DownloadIcon fill="#0000ff" />
              </div>
            }
            size="Medium"
            type="submit"
            style={{
              marginLeft: "auto",
              border: "1px solid #0000ff",
              borderRadius: 0,
              textTransform: "unset",
              paddingLeft: "20px",
              paddingRight: "20px",
              minWidth: "unset",
              color: "#0000ff",
              height: "30px",
            }}
            onClick={onDownloadClick}
          >
            {downloadLoading ? "Loading..." : "Download Statement "}
          </Button>
        </div>
        <div className="table-orders_body">
          {data?.map((card, index) => (
            <TableItem card={card} key={index} />
          ))}
        </div>
      </div>
    </div>
  )
}
