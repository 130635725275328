import gql from "graphql-tag"
import * as Urql from "urql"
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: any
  Id: any
  Id32: any
  Time: any
}

export type Account = {
  __typename?: "Account"
  availableAmount: Scalars["Float"]
  balance: Scalars["Float"]
  blockedAmount: Scalars["Float"]
  currencyCode: CurrencyCode
}

export type Address = {
  PostalCode: Scalars["String"]
  address1: Scalars["String"]
  address2: Scalars["String"]
  city: Scalars["String"]
  companyName: Scalars["String"]
  countryCode: CountryCode
  dispatchMethod: DeliveryType
  firstName: Scalars["String"]
  lastName: Scalars["String"]
  phone: Scalars["String"]
}

export type Card = {
  __typename?: "Card"
  account?: Maybe<Account>
  embossingCompanyName: Scalars["String"]
  embossingFirstName: Scalars["String"]
  embossingLastName: Scalars["String"]
  embossingName: Scalars["String"]
  expiryDate: Scalars["Time"]
  id: Scalars["String"]
  limits?: Maybe<Limits>
  maskedCardNumber: Scalars["String"]
  name: Scalars["String"]
  status: CardStatus
  type: Scalars["String"]
}

export enum CardStatus {
  Active = "Active",
  AwaitingRenewal = "AwaitingRenewal",
  Blocked = "Blocked",
  Closed = "Closed",
  Closing = "Closing",
  Completed = "Completed",
  Created = "Created",
  Dispatched = "Dispatched",
  Expired = "Expired",
  Ordered = "Ordered",
  Personalized = "Personalized",
}

export enum CardTransactionStatus {
  Canceled = "Canceled",
  Completed = "Completed",
  Pending = "Pending",
}

export type CardTransactionsFilter = {
  from?: InputMaybe<Scalars["Time"]>
  page?: InputMaybe<ExprPage>
  searchKeyword?: InputMaybe<Scalars["String"]>
  status?: InputMaybe<CardTransactionStatus>
  to?: InputMaybe<Scalars["Time"]>
}

export type Cards = {
  __typename?: "Cards"
  count?: Maybe<Scalars["Int"]>
  data?: Maybe<Array<Maybe<Card>>>
}

export type Client = {
  __typename?: "Client"
  externalID?: Maybe<Scalars["String"]>
  group?: Maybe<Group>
  ownerID: Scalars["String"]
}

export type ClientCompanyRequest = {
  businessRelationshipPurpose: Scalars["String"]
  dateOfIncorporation: Scalars["Time"]
  email: Scalars["String"]
  headquarterAddress: CompanyAddress
  industryType: Scalars["String"]
  isAdverseMediaInvolved: Scalars["Boolean"]
  isSanctionsRelated: Scalars["Boolean"]
  mobile: Scalars["String"]
  name: Scalars["String"]
  registrationAddress: CompanyAddress
  registrationNumber: Scalars["String"]
  riskProfile: RiskProfile
}

export type ClientPersonRequest = {
  Address: PersonAddress
  BirthCountryCode: CountryCode
  Gender: Gender
  IsBeneficialOwner: Scalars["Boolean"]
  PersonalNumberIssuer: CountryCode
  ResidenceCountryCode: CountryCode
  RiskProfile: PersonRiskProfile
  birthDate: Scalars["Time"]
  businessRelationshipPurpose: Scalars["String"]
  documentNumber: Scalars["String"]
  email: Scalars["String"]
  firstName: Scalars["String"]
  isAdverseMediaInvolved: Scalars["Boolean"]
  isPoliticallyExposedPerson: Scalars["Boolean"]
  isRepresentedBySomeoneElse: Scalars["Boolean"]
  isSanctionsRelated: Scalars["Boolean"]
  lastName: Scalars["String"]
  middleName: Scalars["String"]
  mobile: Scalars["String"]
  politicallyExposedPersonExplanation?: InputMaybe<Scalars["String"]>
}

export type CompanyAddress = {
  Address1: Scalars["String"]
  Address2: Scalars["String"]
  City: Scalars["String"]
  CountryCode: CountryCode
  PostalCode: Scalars["String"]
}

export enum CountryCode {
  Abw = "ABW",
  Afg = "AFG",
  Ago = "AGO",
  Aia = "AIA",
  Ala = "ALA",
  Alb = "ALB",
  And = "AND",
  Are = "ARE",
  Arg = "ARG",
  Arm = "ARM",
  Asm = "ASM",
  Ata = "ATA",
  Atf = "ATF",
  Atg = "ATG",
  Aus = "AUS",
  Aut = "AUT",
  Aze = "AZE",
  Bdi = "BDI",
  Bel = "BEL",
  Ben = "BEN",
  Bes = "BES",
  Bfa = "BFA",
  Bgd = "BGD",
  Bgr = "BGR",
  Bhr = "BHR",
  Bhs = "BHS",
  Bih = "BIH",
  Blm = "BLM",
  Blr = "BLR",
  Blz = "BLZ",
  Bmu = "BMU",
  Bol = "BOL",
  Bra = "BRA",
  Brb = "BRB",
  Brn = "BRN",
  Btn = "BTN",
  Bvt = "BVT",
  Bwa = "BWA",
  Caf = "CAF",
  Can = "CAN",
  Cck = "CCK",
  Che = "CHE",
  Chl = "CHL",
  Chn = "CHN",
  Civ = "CIV",
  Cmr = "CMR",
  Cod = "COD",
  Cog = "COG",
  Cok = "COK",
  Col = "COL",
  Com = "COM",
  Cpv = "CPV",
  Cri = "CRI",
  Cub = "CUB",
  Cuw = "CUW",
  Cxr = "CXR",
  Cym = "CYM",
  Cyp = "CYP",
  Cze = "CZE",
  Deu = "DEU",
  Dji = "DJI",
  Dma = "DMA",
  Dnk = "DNK",
  Dom = "DOM",
  Dza = "DZA",
  Ecu = "ECU",
  Egy = "EGY",
  Eri = "ERI",
  Esh = "ESH",
  Esp = "ESP",
  Est = "EST",
  Eth = "ETH",
  Fin = "FIN",
  Fji = "FJI",
  Flk = "FLK",
  Fra = "FRA",
  Fro = "FRO",
  Fsm = "FSM",
  Gab = "GAB",
  Gbr = "GBR",
  Geo = "GEO",
  Ggy = "GGY",
  Gha = "GHA",
  Gib = "GIB",
  Gin = "GIN",
  Glp = "GLP",
  Gmb = "GMB",
  Gnb = "GNB",
  Gnq = "GNQ",
  Grc = "GRC",
  Grd = "GRD",
  Grl = "GRL",
  Gtm = "GTM",
  Guf = "GUF",
  Gum = "GUM",
  Guy = "GUY",
  Hkg = "HKG",
  Hmd = "HMD",
  Hnd = "HND",
  Hrv = "HRV",
  Hti = "HTI",
  Hun = "HUN",
  Idn = "IDN",
  Imn = "IMN",
  Ind = "IND",
  Iot = "IOT",
  Irl = "IRL",
  Irn = "IRN",
  Irq = "IRQ",
  Isl = "ISL",
  Isr = "ISR",
  Ita = "ITA",
  Jam = "JAM",
  Jey = "JEY",
  Jor = "JOR",
  Jpn = "JPN",
  Kaz = "KAZ",
  Ken = "KEN",
  Kgz = "KGZ",
  Khm = "KHM",
  Kir = "KIR",
  Kna = "KNA",
  Kor = "KOR",
  Kwt = "KWT",
  Lao = "LAO",
  Lbn = "LBN",
  Lbr = "LBR",
  Lby = "LBY",
  Lca = "LCA",
  Lie = "LIE",
  Lka = "LKA",
  Lso = "LSO",
  Ltu = "LTU",
  Lux = "LUX",
  Lva = "LVA",
  Mac = "MAC",
  Maf = "MAF",
  Mar = "MAR",
  Mco = "MCO",
  Mda = "MDA",
  Mdg = "MDG",
  Mdv = "MDV",
  Mex = "MEX",
  Mhl = "MHL",
  Mkd = "MKD",
  Mli = "MLI",
  Mlt = "MLT",
  Mmr = "MMR",
  Mne = "MNE",
  Mng = "MNG",
  Mnp = "MNP",
  Moz = "MOZ",
  Mrt = "MRT",
  Msr = "MSR",
  Mtq = "MTQ",
  Mus = "MUS",
  Mwi = "MWI",
  Mys = "MYS",
  Myt = "MYT",
  Nam = "NAM",
  Ncl = "NCL",
  Ner = "NER",
  Nfk = "NFK",
  Nga = "NGA",
  Nic = "NIC",
  Niu = "NIU",
  Nld = "NLD",
  Nor = "NOR",
  Npl = "NPL",
  Nru = "NRU",
  Nzl = "NZL",
  Omn = "OMN",
  Pak = "PAK",
  Pan = "PAN",
  Pcn = "PCN",
  Per = "PER",
  Phl = "PHL",
  Plw = "PLW",
  Png = "PNG",
  Pol = "POL",
  Pri = "PRI",
  Prk = "PRK",
  Prt = "PRT",
  Pry = "PRY",
  Pse = "PSE",
  Pyf = "PYF",
  Qat = "QAT",
  Reu = "REU",
  Rou = "ROU",
  Rus = "RUS",
  Rwa = "RWA",
  Sau = "SAU",
  Sdn = "SDN",
  Sen = "SEN",
  Sgp = "SGP",
  Sgs = "SGS",
  Shn = "SHN",
  Sjm = "SJM",
  Slb = "SLB",
  Sle = "SLE",
  Slv = "SLV",
  Smr = "SMR",
  Som = "SOM",
  Spm = "SPM",
  Srb = "SRB",
  Ssd = "SSD",
  Stp = "STP",
  Sur = "SUR",
  Svk = "SVK",
  Svn = "SVN",
  Swe = "SWE",
  Swz = "SWZ",
  Sxm = "SXM",
  Syc = "SYC",
  Syr = "SYR",
  Tca = "TCA",
  Tcd = "TCD",
  Tgo = "TGO",
  Tha = "THA",
  Tjk = "TJK",
  Tkl = "TKL",
  Tkm = "TKM",
  Tls = "TLS",
  Ton = "TON",
  Tto = "TTO",
  Tun = "TUN",
  Tur = "TUR",
  Tuv = "TUV",
  Twn = "TWN",
  Tza = "TZA",
  Uga = "UGA",
  Ukr = "UKR",
  Umi = "UMI",
  Unk = "UNK",
  Ury = "URY",
  Usa = "USA",
  Uzb = "UZB",
  Vat = "VAT",
  Vct = "VCT",
  Ven = "VEN",
  Vgb = "VGB",
  Vir = "VIR",
  Vnm = "VNM",
  Vut = "VUT",
  Wlf = "WLF",
  Wsm = "WSM",
  Yem = "YEM",
  Zaf = "ZAF",
  Zmb = "ZMB",
  Zwe = "ZWE",
}

export enum CurrencyCode {
  Eur = "EUR",
}

export enum DeliveryType {
  DhlExpress = "DHLExpress",
  DhlGlobalMail = "DHLGlobalMail",
}

export enum ExpFilterOp {
  Eq = "EQ",
  Like = "LIKE",
}

export type Expr = {
  filter?: InputMaybe<Array<ExprFilter>>
  ids?: InputMaybe<ExprFilterIds>
  order?: InputMaybe<ExprOrder>
  page?: InputMaybe<ExprPage>
}

export type ExprFilter = {
  column?: InputMaybe<Scalars["String"]>
  operation?: InputMaybe<ExpFilterOp>
  value?: InputMaybe<Scalars["String"]>
}

export type ExprFilterIds = {
  column?: InputMaybe<Scalars["String"]>
  in?: InputMaybe<Array<Scalars["Int"]>>
}

export type ExprL = {
  order?: InputMaybe<ExprOrder>
  page?: InputMaybe<ExprPage>
}

export type ExprOrder = {
  asc?: InputMaybe<Scalars["Boolean"]>
  column?: InputMaybe<Scalars["String"]>
}

export type ExprPage = {
  count?: InputMaybe<Scalars["Int"]>
  num?: InputMaybe<Scalars["Int"]>
}

export enum Gender {
  Female = "FEMALE",
  Male = "MALE",
}

export type Group = {
  __typename?: "Group"
  name: Scalars["String"]
  project?: Maybe<Project>
  uuid: Scalars["String"]
}

export type Limits = {
  __typename?: "Limits"
  dailyContactlessPurchase?: Maybe<Scalars["Float"]>
  dailyContactlessPurchaseAvailable: Scalars["Float"]
  dailyContactlessPurchaseUsed: Scalars["Float"]
  dailyInternetPurchase?: Maybe<Scalars["Float"]>
  dailyInternetPurchaseAvailable: Scalars["Float"]
  dailyInternetPurchaseUsed: Scalars["Float"]
  dailyOverallPurchase?: Maybe<Scalars["Float"]>
  dailyOverallPurchaseAvailable: Scalars["Float"]
  dailyOverallPurchaseUsed: Scalars["Float"]
  dailyWithdrawal?: Maybe<Scalars["Float"]>
  dailyWithdrawalAvailable: Scalars["Float"]
  dailyWithdrawalUsed: Scalars["Float"]
  monthlyContactlessPurchase?: Maybe<Scalars["Float"]>
  monthlyContactlessPurchaseAvailable: Scalars["Float"]
  monthlyContactlessPurchaseUsed: Scalars["Float"]
  monthlyInternetPurchase?: Maybe<Scalars["Float"]>
  monthlyInternetPurchaseAvailable: Scalars["Float"]
  monthlyInternetPurchaseUsed: Scalars["Float"]
  monthlyOverallPurchase?: Maybe<Scalars["Float"]>
  monthlyOverallPurchaseAvailable: Scalars["Float"]
  monthlyOverallPurchaseUsed: Scalars["Float"]
  monthlyWithdrawal?: Maybe<Scalars["Float"]>
  monthlyWithdrawalAvailable: Scalars["Float"]
  monthlyWithdrawalUsed: Scalars["Float"]
}

export type Mutation = {
  __typename?: "Mutation"
  cardBlock?: Maybe<Card>
  cardClose?: Maybe<Card>
  cardSet3dsPassword?: Maybe<Card>
  cardSetLimits?: Maybe<Card>
  cardSetName?: Maybe<Card>
  cardUnblock?: Maybe<Card>
  /** Add new card */
  cardsNew?: Maybe<Card>
  cardsPhysicalNew?: Maybe<Card>
  refreshToken: Scalars["String"]
}

export type MutationCardBlockArgs = {
  cardId: Scalars["String"]
}

export type MutationCardCloseArgs = {
  cardId: Scalars["String"]
}

export type MutationCardSet3dsPasswordArgs = {
  cardId: Scalars["String"]
  password: Scalars["String"]
  phone: Scalars["String"]
}

export type MutationCardSetLimitsArgs = {
  cardId: Scalars["String"]
  limits: SetCardLimits
}

export type MutationCardSetNameArgs = {
  cardId: Scalars["String"]
  name: Scalars["String"]
}

export type MutationCardUnblockArgs = {
  cardId: Scalars["String"]
}

export type MutationCardsNewArgs = {
  in: NewCardRequest
}

export type MutationCardsPhysicalNewArgs = {
  in: NewCardPhysicalRequest
}

export type NewCardPhysicalRequest = {
  currencyCode: CurrencyCode
  deliveryTo: Address
  mobile: Scalars["String"]
  name: Scalars["String"]
  password3ds: Scalars["String"]
  pin?: InputMaybe<Scalars["Int"]>
  printOnCard: Scalars["String"]
}

export type NewCardRequest = {
  currencyCode: CurrencyCode
  mobile: Scalars["String"]
  name: Scalars["String"]
  password3ds: Scalars["String"]
}

export type PersonAddress = {
  Address1: Scalars["String"]
  Address2: Scalars["String"]
  City: Scalars["String"]
  CountryCode: CountryCode
  PostalCode: Scalars["String"]
}

export enum PersonRiskProfile {
  High = "High",
  Low = "Low",
  Medium = "Medium",
}

export type Project = {
  __typename?: "Project"
  type?: Maybe<ProjectType>
  uuid: Scalars["String"]
}

export enum ProjectType {
  Corporate = "Corporate",
  Individual = "Individual",
}

export type Query = {
  __typename?: "Query"
  card: Card
  cardCvv: Scalars["String"]
  cardNumber: Scalars["String"]
  cardTransactions?: Maybe<Transactions>
  cards?: Maybe<Cards>
  client: Client
}

export type QueryCardArgs = {
  id: Scalars["String"]
}

export type QueryCardCvvArgs = {
  cardId: Scalars["String"]
}

export type QueryCardNumberArgs = {
  cardId: Scalars["String"]
}

export type QueryCardTransactionsArgs = {
  cardId: Scalars["String"]
  filter?: InputMaybe<CardTransactionsFilter>
}

export type QueryCardsArgs = {
  expr?: InputMaybe<ExprL>
  statuses?: InputMaybe<Array<Scalars["String"]>>
}

export enum RiskProfile {
  High = "High",
  Low = "Low",
  Medium = "Medium",
  Pending = "Pending",
  Prohibited = "Prohibited",
}

export type SetCardLimits = {
  dailyContactlessPurchase?: InputMaybe<Scalars["Float"]>
  dailyInternetPurchase?: InputMaybe<Scalars["Float"]>
  dailyOverallPurchase?: InputMaybe<Scalars["Float"]>
  dailyWithdrawal?: InputMaybe<Scalars["Float"]>
  monthlyContactlessPurchase?: InputMaybe<Scalars["Float"]>
  monthlyInternetPurchase?: InputMaybe<Scalars["Float"]>
  monthlyOverallPurchase?: InputMaybe<Scalars["Float"]>
  monthlyWithdrawal?: InputMaybe<Scalars["Float"]>
}

export enum Status {
  AlreadyExists = "ALREADY_EXISTS",
  ApiError = "API_ERROR",
  NotFound = "NOT_FOUND",
  RelationshipsViolation = "RELATIONSHIPS_VIOLATION",
  Success = "SUCCESS",
  Unknown = "UNKNOWN",
}

export type StatusReply = {
  __typename?: "StatusReply"
  message: Scalars["String"]
  statusCode: Status
  success: Scalars["Boolean"]
}

export type Transaction = {
  __typename?: "Transaction"
  accountAmount?: Maybe<Scalars["Float"]>
  accountCurrencyCode?: Maybe<CurrencyCode>
  date: Scalars["Time"]
  description: Scalars["String"]
  group: Scalars["String"]
  id: Scalars["String"]
  isDeclined?: Maybe<Scalars["Boolean"]>
  maskedCardNumber: Scalars["String"]
  merchantName: Scalars["String"]
  purchaseDate: Scalars["Time"]
  status: Scalars["String"]
  transactionAmount?: Maybe<Scalars["Float"]>
  transactionCurrencyCode?: Maybe<CurrencyCode>
  type: Scalars["String"]
}

export type Transactions = {
  __typename?: "Transactions"
  count?: Maybe<Scalars["Int"]>
  data?: Maybe<Array<Maybe<Transaction>>>
}

export type AccountFragment = {
  __typename?: "Account"
  availableAmount: number
  balance: number
  blockedAmount: number
  currencyCode: CurrencyCode
}

export type LimitsFragment = {
  __typename?: "Limits"
  dailyContactlessPurchase?: number | null
  dailyContactlessPurchaseAvailable: number
  dailyContactlessPurchaseUsed: number
  dailyInternetPurchase?: number | null
  dailyInternetPurchaseAvailable: number
  dailyInternetPurchaseUsed: number
  dailyOverallPurchase?: number | null
  dailyOverallPurchaseAvailable: number
  dailyOverallPurchaseUsed: number
  dailyWithdrawal?: number | null
  dailyWithdrawalAvailable: number
  dailyWithdrawalUsed: number
  monthlyContactlessPurchase?: number | null
  monthlyContactlessPurchaseAvailable: number
  monthlyContactlessPurchaseUsed: number
  monthlyInternetPurchase?: number | null
  monthlyInternetPurchaseAvailable: number
  monthlyInternetPurchaseUsed: number
  monthlyOverallPurchase?: number | null
  monthlyOverallPurchaseAvailable: number
  monthlyOverallPurchaseUsed: number
  monthlyWithdrawal?: number | null
  monthlyWithdrawalAvailable: number
  monthlyWithdrawalUsed: number
}

export type CardFragment = {
  __typename?: "Card"
  id: string
  name: string
  maskedCardNumber: string
  expiryDate: any
  type: string
  status: CardStatus
  embossingCompanyName: string
  embossingFirstName: string
  embossingLastName: string
  embossingName: string
  account?: {
    __typename?: "Account"
    availableAmount: number
    balance: number
    blockedAmount: number
    currencyCode: CurrencyCode
  } | null
  limits?: {
    __typename?: "Limits"
    dailyContactlessPurchase?: number | null
    dailyContactlessPurchaseAvailable: number
    dailyContactlessPurchaseUsed: number
    dailyInternetPurchase?: number | null
    dailyInternetPurchaseAvailable: number
    dailyInternetPurchaseUsed: number
    dailyOverallPurchase?: number | null
    dailyOverallPurchaseAvailable: number
    dailyOverallPurchaseUsed: number
    dailyWithdrawal?: number | null
    dailyWithdrawalAvailable: number
    dailyWithdrawalUsed: number
    monthlyContactlessPurchase?: number | null
    monthlyContactlessPurchaseAvailable: number
    monthlyContactlessPurchaseUsed: number
    monthlyInternetPurchase?: number | null
    monthlyInternetPurchaseAvailable: number
    monthlyInternetPurchaseUsed: number
    monthlyOverallPurchase?: number | null
    monthlyOverallPurchaseAvailable: number
    monthlyOverallPurchaseUsed: number
    monthlyWithdrawal?: number | null
    monthlyWithdrawalAvailable: number
    monthlyWithdrawalUsed: number
  } | null
}

export type GetCardQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type GetCardQuery = {
  __typename?: "Query"
  card: {
    __typename?: "Card"
    id: string
    name: string
    maskedCardNumber: string
    expiryDate: any
    type: string
    status: CardStatus
    embossingCompanyName: string
    embossingFirstName: string
    embossingLastName: string
    embossingName: string
    account?: {
      __typename?: "Account"
      availableAmount: number
      balance: number
      blockedAmount: number
      currencyCode: CurrencyCode
    } | null
    limits?: {
      __typename?: "Limits"
      dailyContactlessPurchase?: number | null
      dailyContactlessPurchaseAvailable: number
      dailyContactlessPurchaseUsed: number
      dailyInternetPurchase?: number | null
      dailyInternetPurchaseAvailable: number
      dailyInternetPurchaseUsed: number
      dailyOverallPurchase?: number | null
      dailyOverallPurchaseAvailable: number
      dailyOverallPurchaseUsed: number
      dailyWithdrawal?: number | null
      dailyWithdrawalAvailable: number
      dailyWithdrawalUsed: number
      monthlyContactlessPurchase?: number | null
      monthlyContactlessPurchaseAvailable: number
      monthlyContactlessPurchaseUsed: number
      monthlyInternetPurchase?: number | null
      monthlyInternetPurchaseAvailable: number
      monthlyInternetPurchaseUsed: number
      monthlyOverallPurchase?: number | null
      monthlyOverallPurchaseAvailable: number
      monthlyOverallPurchaseUsed: number
      monthlyWithdrawal?: number | null
      monthlyWithdrawalAvailable: number
      monthlyWithdrawalUsed: number
    } | null
  }
}

export type GetCardsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>
  limit?: InputMaybe<Scalars["Int"]>
  order?: InputMaybe<ExprOrder>
  statuses?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>
}>

export type GetCardsQuery = {
  __typename?: "Query"
  cards?: {
    __typename?: "Cards"
    count?: number | null
    data?: Array<{
      __typename?: "Card"
      id: string
      name: string
      maskedCardNumber: string
      expiryDate: any
      type: string
      status: CardStatus
      embossingCompanyName: string
      embossingFirstName: string
      embossingLastName: string
      embossingName: string
      account?: {
        __typename?: "Account"
        availableAmount: number
        balance: number
        blockedAmount: number
        currencyCode: CurrencyCode
      } | null
      limits?: {
        __typename?: "Limits"
        dailyContactlessPurchase?: number | null
        dailyContactlessPurchaseAvailable: number
        dailyContactlessPurchaseUsed: number
        dailyInternetPurchase?: number | null
        dailyInternetPurchaseAvailable: number
        dailyInternetPurchaseUsed: number
        dailyOverallPurchase?: number | null
        dailyOverallPurchaseAvailable: number
        dailyOverallPurchaseUsed: number
        dailyWithdrawal?: number | null
        dailyWithdrawalAvailable: number
        dailyWithdrawalUsed: number
        monthlyContactlessPurchase?: number | null
        monthlyContactlessPurchaseAvailable: number
        monthlyContactlessPurchaseUsed: number
        monthlyInternetPurchase?: number | null
        monthlyInternetPurchaseAvailable: number
        monthlyInternetPurchaseUsed: number
        monthlyOverallPurchase?: number | null
        monthlyOverallPurchaseAvailable: number
        monthlyOverallPurchaseUsed: number
        monthlyWithdrawal?: number | null
        monthlyWithdrawalAvailable: number
        monthlyWithdrawalUsed: number
      } | null
    } | null> | null
  } | null
}

export type GetCardCvvQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type GetCardCvvQuery = { __typename?: "Query"; cardCvv: string }

export type GetCardNumberQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type GetCardNumberQuery = { __typename?: "Query"; cardNumber: string }

export type GetCardNumberAndCvvQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type GetCardNumberAndCvvQuery = {
  __typename?: "Query"
  cardCvv: string
  cardNumber: string
}

export type CreateCardVirtualMutationVariables = Exact<{
  currencyCode: CurrencyCode
  mobile: Scalars["String"]
  name: Scalars["String"]
  password3ds: Scalars["String"]
}>

export type CreateCardVirtualMutation = {
  __typename?: "Mutation"
  cardsNew?: {
    __typename?: "Card"
    id: string
    name: string
    maskedCardNumber: string
    expiryDate: any
    type: string
    status: CardStatus
    embossingCompanyName: string
    embossingFirstName: string
    embossingLastName: string
    embossingName: string
    account?: {
      __typename?: "Account"
      availableAmount: number
      balance: number
      blockedAmount: number
      currencyCode: CurrencyCode
    } | null
    limits?: {
      __typename?: "Limits"
      dailyContactlessPurchase?: number | null
      dailyContactlessPurchaseAvailable: number
      dailyContactlessPurchaseUsed: number
      dailyInternetPurchase?: number | null
      dailyInternetPurchaseAvailable: number
      dailyInternetPurchaseUsed: number
      dailyOverallPurchase?: number | null
      dailyOverallPurchaseAvailable: number
      dailyOverallPurchaseUsed: number
      dailyWithdrawal?: number | null
      dailyWithdrawalAvailable: number
      dailyWithdrawalUsed: number
      monthlyContactlessPurchase?: number | null
      monthlyContactlessPurchaseAvailable: number
      monthlyContactlessPurchaseUsed: number
      monthlyInternetPurchase?: number | null
      monthlyInternetPurchaseAvailable: number
      monthlyInternetPurchaseUsed: number
      monthlyOverallPurchase?: number | null
      monthlyOverallPurchaseAvailable: number
      monthlyOverallPurchaseUsed: number
      monthlyWithdrawal?: number | null
      monthlyWithdrawalAvailable: number
      monthlyWithdrawalUsed: number
    } | null
  } | null
}

export type CreateCardPhysicalMutationVariables = Exact<{
  currencyCode: CurrencyCode
  deliveryTo: Address
  mobile: Scalars["String"]
  name: Scalars["String"]
  password3ds: Scalars["String"]
  pin?: InputMaybe<Scalars["Int"]>
  printOnCard: Scalars["String"]
}>

export type CreateCardPhysicalMutation = {
  __typename?: "Mutation"
  cardsPhysicalNew?: {
    __typename?: "Card"
    id: string
    name: string
    maskedCardNumber: string
    expiryDate: any
    type: string
    status: CardStatus
    embossingCompanyName: string
    embossingFirstName: string
    embossingLastName: string
    embossingName: string
    account?: {
      __typename?: "Account"
      availableAmount: number
      balance: number
      blockedAmount: number
      currencyCode: CurrencyCode
    } | null
    limits?: {
      __typename?: "Limits"
      dailyContactlessPurchase?: number | null
      dailyContactlessPurchaseAvailable: number
      dailyContactlessPurchaseUsed: number
      dailyInternetPurchase?: number | null
      dailyInternetPurchaseAvailable: number
      dailyInternetPurchaseUsed: number
      dailyOverallPurchase?: number | null
      dailyOverallPurchaseAvailable: number
      dailyOverallPurchaseUsed: number
      dailyWithdrawal?: number | null
      dailyWithdrawalAvailable: number
      dailyWithdrawalUsed: number
      monthlyContactlessPurchase?: number | null
      monthlyContactlessPurchaseAvailable: number
      monthlyContactlessPurchaseUsed: number
      monthlyInternetPurchase?: number | null
      monthlyInternetPurchaseAvailable: number
      monthlyInternetPurchaseUsed: number
      monthlyOverallPurchase?: number | null
      monthlyOverallPurchaseAvailable: number
      monthlyOverallPurchaseUsed: number
      monthlyWithdrawal?: number | null
      monthlyWithdrawalAvailable: number
      monthlyWithdrawalUsed: number
    } | null
  } | null
}

export type BlockCardMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type BlockCardMutation = {
  __typename?: "Mutation"
  cardBlock?: {
    __typename?: "Card"
    id: string
    name: string
    maskedCardNumber: string
    expiryDate: any
    type: string
    status: CardStatus
    embossingCompanyName: string
    embossingFirstName: string
    embossingLastName: string
    embossingName: string
    account?: {
      __typename?: "Account"
      availableAmount: number
      balance: number
      blockedAmount: number
      currencyCode: CurrencyCode
    } | null
    limits?: {
      __typename?: "Limits"
      dailyContactlessPurchase?: number | null
      dailyContactlessPurchaseAvailable: number
      dailyContactlessPurchaseUsed: number
      dailyInternetPurchase?: number | null
      dailyInternetPurchaseAvailable: number
      dailyInternetPurchaseUsed: number
      dailyOverallPurchase?: number | null
      dailyOverallPurchaseAvailable: number
      dailyOverallPurchaseUsed: number
      dailyWithdrawal?: number | null
      dailyWithdrawalAvailable: number
      dailyWithdrawalUsed: number
      monthlyContactlessPurchase?: number | null
      monthlyContactlessPurchaseAvailable: number
      monthlyContactlessPurchaseUsed: number
      monthlyInternetPurchase?: number | null
      monthlyInternetPurchaseAvailable: number
      monthlyInternetPurchaseUsed: number
      monthlyOverallPurchase?: number | null
      monthlyOverallPurchaseAvailable: number
      monthlyOverallPurchaseUsed: number
      monthlyWithdrawal?: number | null
      monthlyWithdrawalAvailable: number
      monthlyWithdrawalUsed: number
    } | null
  } | null
}

export type UnblockCardMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type UnblockCardMutation = {
  __typename?: "Mutation"
  cardUnblock?: {
    __typename?: "Card"
    id: string
    name: string
    maskedCardNumber: string
    expiryDate: any
    type: string
    status: CardStatus
    embossingCompanyName: string
    embossingFirstName: string
    embossingLastName: string
    embossingName: string
    account?: {
      __typename?: "Account"
      availableAmount: number
      balance: number
      blockedAmount: number
      currencyCode: CurrencyCode
    } | null
    limits?: {
      __typename?: "Limits"
      dailyContactlessPurchase?: number | null
      dailyContactlessPurchaseAvailable: number
      dailyContactlessPurchaseUsed: number
      dailyInternetPurchase?: number | null
      dailyInternetPurchaseAvailable: number
      dailyInternetPurchaseUsed: number
      dailyOverallPurchase?: number | null
      dailyOverallPurchaseAvailable: number
      dailyOverallPurchaseUsed: number
      dailyWithdrawal?: number | null
      dailyWithdrawalAvailable: number
      dailyWithdrawalUsed: number
      monthlyContactlessPurchase?: number | null
      monthlyContactlessPurchaseAvailable: number
      monthlyContactlessPurchaseUsed: number
      monthlyInternetPurchase?: number | null
      monthlyInternetPurchaseAvailable: number
      monthlyInternetPurchaseUsed: number
      monthlyOverallPurchase?: number | null
      monthlyOverallPurchaseAvailable: number
      monthlyOverallPurchaseUsed: number
      monthlyWithdrawal?: number | null
      monthlyWithdrawalAvailable: number
      monthlyWithdrawalUsed: number
    } | null
  } | null
}

export type CloseCardMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type CloseCardMutation = {
  __typename?: "Mutation"
  cardClose?: {
    __typename?: "Card"
    id: string
    name: string
    maskedCardNumber: string
    expiryDate: any
    type: string
    status: CardStatus
    embossingCompanyName: string
    embossingFirstName: string
    embossingLastName: string
    embossingName: string
    account?: {
      __typename?: "Account"
      availableAmount: number
      balance: number
      blockedAmount: number
      currencyCode: CurrencyCode
    } | null
    limits?: {
      __typename?: "Limits"
      dailyContactlessPurchase?: number | null
      dailyContactlessPurchaseAvailable: number
      dailyContactlessPurchaseUsed: number
      dailyInternetPurchase?: number | null
      dailyInternetPurchaseAvailable: number
      dailyInternetPurchaseUsed: number
      dailyOverallPurchase?: number | null
      dailyOverallPurchaseAvailable: number
      dailyOverallPurchaseUsed: number
      dailyWithdrawal?: number | null
      dailyWithdrawalAvailable: number
      dailyWithdrawalUsed: number
      monthlyContactlessPurchase?: number | null
      monthlyContactlessPurchaseAvailable: number
      monthlyContactlessPurchaseUsed: number
      monthlyInternetPurchase?: number | null
      monthlyInternetPurchaseAvailable: number
      monthlyInternetPurchaseUsed: number
      monthlyOverallPurchase?: number | null
      monthlyOverallPurchaseAvailable: number
      monthlyOverallPurchaseUsed: number
      monthlyWithdrawal?: number | null
      monthlyWithdrawalAvailable: number
      monthlyWithdrawalUsed: number
    } | null
  } | null
}

export type SetCardNameMutationVariables = Exact<{
  id: Scalars["String"]
  name: Scalars["String"]
}>

export type SetCardNameMutation = {
  __typename?: "Mutation"
  cardSetName?: {
    __typename?: "Card"
    id: string
    name: string
    maskedCardNumber: string
    expiryDate: any
    type: string
    status: CardStatus
    embossingCompanyName: string
    embossingFirstName: string
    embossingLastName: string
    embossingName: string
    account?: {
      __typename?: "Account"
      availableAmount: number
      balance: number
      blockedAmount: number
      currencyCode: CurrencyCode
    } | null
    limits?: {
      __typename?: "Limits"
      dailyContactlessPurchase?: number | null
      dailyContactlessPurchaseAvailable: number
      dailyContactlessPurchaseUsed: number
      dailyInternetPurchase?: number | null
      dailyInternetPurchaseAvailable: number
      dailyInternetPurchaseUsed: number
      dailyOverallPurchase?: number | null
      dailyOverallPurchaseAvailable: number
      dailyOverallPurchaseUsed: number
      dailyWithdrawal?: number | null
      dailyWithdrawalAvailable: number
      dailyWithdrawalUsed: number
      monthlyContactlessPurchase?: number | null
      monthlyContactlessPurchaseAvailable: number
      monthlyContactlessPurchaseUsed: number
      monthlyInternetPurchase?: number | null
      monthlyInternetPurchaseAvailable: number
      monthlyInternetPurchaseUsed: number
      monthlyOverallPurchase?: number | null
      monthlyOverallPurchaseAvailable: number
      monthlyOverallPurchaseUsed: number
      monthlyWithdrawal?: number | null
      monthlyWithdrawalAvailable: number
      monthlyWithdrawalUsed: number
    } | null
  } | null
}

export type SetCard3dsPasswordMutationVariables = Exact<{
  id: Scalars["String"]
  password: Scalars["String"]
  phone: Scalars["String"]
}>

export type SetCard3dsPasswordMutation = {
  __typename?: "Mutation"
  cardSet3dsPassword?: {
    __typename?: "Card"
    id: string
    name: string
    maskedCardNumber: string
    expiryDate: any
    type: string
    status: CardStatus
    embossingCompanyName: string
    embossingFirstName: string
    embossingLastName: string
    embossingName: string
    account?: {
      __typename?: "Account"
      availableAmount: number
      balance: number
      blockedAmount: number
      currencyCode: CurrencyCode
    } | null
    limits?: {
      __typename?: "Limits"
      dailyContactlessPurchase?: number | null
      dailyContactlessPurchaseAvailable: number
      dailyContactlessPurchaseUsed: number
      dailyInternetPurchase?: number | null
      dailyInternetPurchaseAvailable: number
      dailyInternetPurchaseUsed: number
      dailyOverallPurchase?: number | null
      dailyOverallPurchaseAvailable: number
      dailyOverallPurchaseUsed: number
      dailyWithdrawal?: number | null
      dailyWithdrawalAvailable: number
      dailyWithdrawalUsed: number
      monthlyContactlessPurchase?: number | null
      monthlyContactlessPurchaseAvailable: number
      monthlyContactlessPurchaseUsed: number
      monthlyInternetPurchase?: number | null
      monthlyInternetPurchaseAvailable: number
      monthlyInternetPurchaseUsed: number
      monthlyOverallPurchase?: number | null
      monthlyOverallPurchaseAvailable: number
      monthlyOverallPurchaseUsed: number
      monthlyWithdrawal?: number | null
      monthlyWithdrawalAvailable: number
      monthlyWithdrawalUsed: number
    } | null
  } | null
}

export const AccountFragmentDoc = gql`
  fragment Account on Account {
    availableAmount
    balance
    blockedAmount
    currencyCode
  }
`
export const LimitsFragmentDoc = gql`
  fragment Limits on Limits {
    dailyContactlessPurchase
    dailyContactlessPurchaseAvailable
    dailyContactlessPurchaseUsed
    dailyInternetPurchase
    dailyInternetPurchaseAvailable
    dailyInternetPurchaseUsed
    dailyOverallPurchase
    dailyOverallPurchaseAvailable
    dailyOverallPurchaseUsed
    dailyWithdrawal
    dailyWithdrawalAvailable
    dailyWithdrawalUsed
    monthlyContactlessPurchase
    monthlyContactlessPurchaseAvailable
    monthlyContactlessPurchaseUsed
    monthlyInternetPurchase
    monthlyInternetPurchaseAvailable
    monthlyInternetPurchaseUsed
    monthlyOverallPurchase
    monthlyOverallPurchaseAvailable
    monthlyOverallPurchaseUsed
    monthlyWithdrawal
    monthlyWithdrawalAvailable
    monthlyWithdrawalUsed
  }
`
export const CardFragmentDoc = gql`
  fragment Card on Card {
    id
    name
    maskedCardNumber
    expiryDate
    type
    status
    embossingCompanyName
    embossingFirstName
    embossingLastName
    embossingName
    account {
      ...Account
    }
    limits {
      ...Limits
    }
  }
  ${AccountFragmentDoc}
  ${LimitsFragmentDoc}
`
export const GetCardDocument = gql`
  query GetCard($id: String!) {
    card(id: $id) {
      ...Card
    }
  }
  ${CardFragmentDoc}
`

export function useGetCardQuery(options: Omit<Urql.UseQueryArgs<GetCardQueryVariables>, "query">) {
  return Urql.useQuery<GetCardQuery, GetCardQueryVariables>({
    query: GetCardDocument,
    ...options,
  })
}
export const GetCardsDocument = gql`
  query GetCards($page: Int, $limit: Int, $order: ExprOrder, $statuses: [String!]) {
    cards(expr: { page: { num: $page, count: $limit }, order: $order }, statuses: $statuses) {
      data {
        ...Card
      }
      count
    }
  }
  ${CardFragmentDoc}
`

export function useGetCardsQuery(
  options?: Omit<Urql.UseQueryArgs<GetCardsQueryVariables>, "query">
) {
  return Urql.useQuery<GetCardsQuery, GetCardsQueryVariables>({
    query: GetCardsDocument,
    ...options,
  })
}
export const GetCardCvvDocument = gql`
  query GetCardCvv($id: String!) {
    cardCvv(cardId: $id)
  }
`

export function useGetCardCvvQuery(
  options: Omit<Urql.UseQueryArgs<GetCardCvvQueryVariables>, "query">
) {
  return Urql.useQuery<GetCardCvvQuery, GetCardCvvQueryVariables>({
    query: GetCardCvvDocument,
    ...options,
  })
}
export const GetCardNumberDocument = gql`
  query GetCardNumber($id: String!) {
    cardNumber(cardId: $id)
  }
`

export function useGetCardNumberQuery(
  options: Omit<Urql.UseQueryArgs<GetCardNumberQueryVariables>, "query">
) {
  return Urql.useQuery<GetCardNumberQuery, GetCardNumberQueryVariables>({
    query: GetCardNumberDocument,
    ...options,
  })
}
export const GetCardNumberAndCvvDocument = gql`
  query GetCardNumberAndCvv($id: String!) {
    cardCvv(cardId: $id)
    cardNumber(cardId: $id)
  }
`

export function useGetCardNumberAndCvvQuery(
  options: Omit<Urql.UseQueryArgs<GetCardNumberAndCvvQueryVariables>, "query">
) {
  return Urql.useQuery<GetCardNumberAndCvvQuery, GetCardNumberAndCvvQueryVariables>({
    query: GetCardNumberAndCvvDocument,
    ...options,
  })
}
export const CreateCardVirtualDocument = gql`
  mutation CreateCardVirtual(
    $currencyCode: CurrencyCode!
    $mobile: String!
    $name: String!
    $password3ds: String!
  ) {
    cardsNew(
      in: { currencyCode: $currencyCode, mobile: $mobile, name: $name, password3ds: $password3ds }
    ) {
      ...Card
    }
  }
  ${CardFragmentDoc}
`

export function useCreateCardVirtualMutation() {
  return Urql.useMutation<CreateCardVirtualMutation, CreateCardVirtualMutationVariables>(
    CreateCardVirtualDocument
  )
}
export const CreateCardPhysicalDocument = gql`
  mutation CreateCardPhysical(
    $currencyCode: CurrencyCode!
    $deliveryTo: Address!
    $mobile: String!
    $name: String!
    $password3ds: String!
    $pin: Int
    $printOnCard: String!
  ) {
    cardsPhysicalNew(
      in: {
        currencyCode: $currencyCode
        deliveryTo: $deliveryTo
        mobile: $mobile
        name: $name
        password3ds: $password3ds
        pin: $pin
        printOnCard: $printOnCard
      }
    ) {
      ...Card
    }
  }
  ${CardFragmentDoc}
`

export function useCreateCardPhysicalMutation() {
  return Urql.useMutation<CreateCardPhysicalMutation, CreateCardPhysicalMutationVariables>(
    CreateCardPhysicalDocument
  )
}
export const BlockCardDocument = gql`
  mutation BlockCard($id: String!) {
    cardBlock(cardId: $id) {
      ...Card
    }
  }
  ${CardFragmentDoc}
`

export function useBlockCardMutation() {
  return Urql.useMutation<BlockCardMutation, BlockCardMutationVariables>(BlockCardDocument)
}
export const UnblockCardDocument = gql`
  mutation UnblockCard($id: String!) {
    cardUnblock(cardId: $id) {
      ...Card
    }
  }
  ${CardFragmentDoc}
`

export function useUnblockCardMutation() {
  return Urql.useMutation<UnblockCardMutation, UnblockCardMutationVariables>(UnblockCardDocument)
}
export const CloseCardDocument = gql`
  mutation CloseCard($id: String!) {
    cardClose(cardId: $id) {
      ...Card
    }
  }
  ${CardFragmentDoc}
`

export function useCloseCardMutation() {
  return Urql.useMutation<CloseCardMutation, CloseCardMutationVariables>(CloseCardDocument)
}
export const SetCardNameDocument = gql`
  mutation SetCardName($id: String!, $name: String!) {
    cardSetName(cardId: $id, name: $name) {
      ...Card
    }
  }
  ${CardFragmentDoc}
`

export function useSetCardNameMutation() {
  return Urql.useMutation<SetCardNameMutation, SetCardNameMutationVariables>(SetCardNameDocument)
}
export const SetCard3dsPasswordDocument = gql`
  mutation SetCard3dsPassword($id: String!, $password: String!, $phone: String!) {
    cardSet3dsPassword(cardId: $id, password: $password, phone: $phone) {
      ...Card
    }
  }
  ${CardFragmentDoc}
`

export function useSetCard3dsPasswordMutation() {
  return Urql.useMutation<SetCard3dsPasswordMutation, SetCard3dsPasswordMutationVariables>(
    SetCard3dsPasswordDocument
  )
}
export type CardSetLimitsMutation = {
  __typename?: "Mutation"
  cardSetLimits?: {
    __typename: "Card"
    id: string
    name: string
    status: CardStatus
    maskedCardNumber: string
    expiryDate: any
    limits?: {
      __typename: "Limits"
      dailyContactlessPurchase?: number | null
      dailyInternetPurchase?: number | null
      dailyOverallPurchase?: number | null
      dailyWithdrawal?: number | null
      monthlyContactlessPurchase?: number | null
      monthlyInternetPurchase?: number | null
      monthlyOverallPurchase?: number | null
      monthlyWithdrawal?: number | null
    } | null
  } | null
}
export type CardSetLimitsMutationVariables = Exact<{
  cardId: Scalars["String"]
  limits: SetCardLimits
}>
export const CardSetLimitsDocument = gql`
  mutation CardSetLimits($cardId: String!, $limits: SetCardLimits!) {
    cardSetLimits(cardId: $cardId, limits: $limits) {
      id
      name
      status
      maskedCardNumber
      expiryDate
      __typename
      limits {
        dailyContactlessPurchase
        dailyInternetPurchase
        dailyOverallPurchase
        dailyWithdrawal
        monthlyContactlessPurchase
        monthlyInternetPurchase
        monthlyOverallPurchase
        monthlyWithdrawal
        __typename
      }
    }
  }
`

export function useCardSetLimitsMutation() {
  return Urql.useMutation<CardSetLimitsMutation, CardSetLimitsMutationVariables>(
    CardSetLimitsDocument
  )
}
