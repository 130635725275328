export const closeModal = (id: string) => {
  // @ts-ignore
  $(`#${id}`).modal("hide")
  $(".modal-backdrop").remove()
}

export const openModal = (id: string) => {
  // @ts-ignore
  $(`#${id}`).modal("show")
}
