import { Container } from "../Container"
import { Image } from "@/Components/common/Image"
import "./style.sass"
import { Link } from "react-router-dom"
import { ThemeMode } from "@/types"
import classNames from "classnames"
import { ReactComponent as MenuBurger } from "@/Images/icon_files/menu-burger.svg"
import { ReactNode, useState } from "react"
import { LanguageSelect } from "@/Components/LanguageSelect/index"
import { Msg } from "@/Components/Msg"
import { useHeaderAuth } from "@usher/pe-client-front-end-lib"

interface IHeaderProps {
  themeMode: ThemeMode
  onlyLogo?: boolean
  smallHeder?: boolean
  additionalContent?: ReactNode
  forAboutPage?: boolean
}

export const Header = ({
  additionalContent,
  forAboutPage,
  smallHeder,
  onlyLogo,
  themeMode,
}: IHeaderProps) => {
  const { isAuthorized, onLogout } = useHeaderAuth()
  const [showMenu, setShowMenu] = useState(false)
  const isDark = themeMode === ThemeMode.Dark
  const navThemePrefix = isDark ? "dark" : "light"
  return (
    <header
      style={smallHeder ? { paddingBottom: 0 } : undefined}
      className={classNames("header", { "onlyLogo--header": onlyLogo })}
    >
      <Container>
        <nav
          style={smallHeder ? { marginBottom: 0 } : undefined}
          className={`nav nav-${navThemePrefix} ${onlyLogo ? "onlyLogo" : ""}`}
        >
          <Link to="/" className="logo">
            <Image src={`logo-${isDark ? "light" : "dark"}.svg`} />
          </Link>
          {!onlyLogo && (
            <>
              <div
                className={`menu_burger${!isDark ? " dark_burger" : ""}`}
                onClick={() => setShowMenu(!showMenu)}
              >
                <MenuBurger />
              </div>
              <div
                onClick={() => setShowMenu(false)}
                className={`nav__content nav-${navThemePrefix}__content${
                  showMenu ? " active" : ""
                }`}
                id="js-menu"
              >
                <div className="nav__pages">
                  <Link to="/exchange" className="nav__link">
                    <Msg id="buy_crypto" />
                  </Link>
                  <Link to="/card" className="nav__link">
                    <Msg id="cards" />
                  </Link>
                  <Link to="/rates" className="nav__link">
                    <Msg id="rates" />
                  </Link>
                  <Link to="/faq" className="nav__link">
                    <Msg id="faq" />
                  </Link>
                </div>
                <div className="nav__auth">
                  <div onClick={(e) => e.stopPropagation()}>
                    <LanguageSelect isDark={isDark && !forAboutPage} />
                  </div>
                  <div>
                    {isAuthorized ? (
                      <a href="/client" className="nav__auth-link">
                        <Msg id="dashboard" />
                      </a>
                    ) : (
                      <Link to="/login" className="nav__auth-link">
                        <Msg id="dashboard_login" />
                      </Link>
                    )}
                    <span> / </span>
                    <Link
                      to="/register"
                      className="nav__auth-link"
                      onClick={(e) => {
                        if (isAuthorized) {
                          e.preventDefault()
                          onLogout()
                        }
                      }}
                    >
                      {isAuthorized ? <Msg id="logout" /> : <Msg id="register" />}
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
        </nav>
        {additionalContent}
      </Container>
    </header>
  )
}
