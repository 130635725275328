import { VALID_PASSWORD, useRecoveryNewPassword } from "@usher/pe-client-front-end-lib"
import { useHistory } from "react-router-dom"
import { Container } from "@/Layout/Container"
import { Text } from "@/Components/Text"
import { FormWrapper } from "@/Components/Form"
import { Paper } from "@/Layout/Paper"
import { Stack } from "@/Layout/Stack"
import { Input } from "@/Components/Form/Input"
import { Button } from "@/Components/Control/Button"
import "./style.css"

export const NewPassword = () => {
  // const [show, setShow] = useState<{ password: boolean; confirm: boolean }>()
  const { formMethods, onSubmit, loading } = useRecoveryNewPassword({ history: useHistory() })

  return (
    <div className="login_wrapper">
      <Container>
        <div className="login_form_head">
          <Text>Password Recovery</Text>
        </div>
        <FormWrapper methods={formMethods} onSubmit={onSubmit}>
          <Paper>
            <Stack>
              <Input name="password" required label="New password" rules={VALID_PASSWORD} />
              <Input
                name="confirm_password"
                required
                label="Confirm new password"
                rules={VALID_PASSWORD}
              />
            </Stack>
            <div style={{ marginTop: 54 }}>
              <Button loading={loading} type="submit">
                Submit
              </Button>
            </div>
          </Paper>
        </FormWrapper>
      </Container>
    </div>
  )
}
