import { LoadingAnimation } from "./TopUpCard/components/LoadingAnimation"

export const LoadingPage = () => {
  return (
    <div className="Loading__Page">
      <LoadingAnimation text="" />
      <h4>Loading...</h4>
    </div>
  )
}
