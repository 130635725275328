import React from "react"

import "./index.sass"
import { Container } from "@/Layout/Container"
import { ExchangeBlock } from "./ExchangeBlock"

export const HeroScreen = () => {
  // const { rates } = useRatesFiat({ history: useHistory() })
  // const rateBTC = rates.find((rate) => rate.symbol === "BTC")
  // const rateETH = rates.find((rate) => rate.symbol === "ETH")
  // const rateBTCValue = rateBTC ? rateBTC.rate : 0
  // const rateETHValue = rateETH ? rateETH.rate : 0

  return (
    <section className="home_section">
      <Container className="hero-container">
        <ExchangeBlock />
      </Container>
    </section>
  )
}
