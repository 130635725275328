import { Image } from "@/Components/common/Image"
import "./style.css"
import { Msg } from "@/Components/Msg"

export const WhyUs = () => {
  return (
    <section className="home_section why">
      <div className="container">
        <h2 className="h2 why__h2">
          <Msg id="why_us" />
        </h2>
        <div className="why__row why__row_fast">
          <picture>
            <source srcSet="/img/1.png" media="(max-width: 768px)" />
            <source srcSet="/img/1-x2.png" media="(max-width: 768px)" />
            <Image src="why-1.png" alt="" />
          </picture>
          <div className="why__fast">
            <h3 className="why__fast-title">
              <Msg id="fast1" />
            </h3>
            <p className="why__fast-text">
              <Msg id="fast2" />
              <br />
              <Msg id="fast3" />
            </p>
          </div>
        </div>
        <div className="why__row why__row_middle">
          <div className="why__row-item">
            <picture>
              <source srcSet="/img/2.png" media="(max-width: 768px)" />
              <source srcSet="/img/2-x2.png" media="(max-width: 768px)" />
              <Image src="why-2.png" alt="" />
            </picture>
            <h3 className="why__row-item-title">
              <Msg id="fast3" />
            </h3>
            <p className="why__row-item-text">
              <Msg id="fast5" />
            </p>
          </div>
          <div className="why__row-item">
            <picture>
              <source srcSet="/img/3.png" media="(max-width: 768px)" />
              <source srcSet="/img/3-x2.png" media="(max-width: 768px)" />
              <Image src="why-3.png" alt="" />
            </picture>
            <h3 className="why__row-item-title">
              <Msg id="fast6" />
            </h3>
            <p className="why__row-item-text">
              <Msg id="fast7" />
            </p>
          </div>
        </div>
        <div className="why__row">
          <div className="why__row-full">
            <picture>
              <source srcSet="/img/4.png" media="(max-width: 768px)" />
              <source srcSet="/img/4-x2.png" media="(max-width: 768px)" />
              <Image src="why-4.png" alt="" />
            </picture>
            <h3 className="why__row-full-title">
              <Msg id="fast8" />
            </h3>
            <div className="why__row-full-text">
              <p>
                <Msg id="fast9" />
              </p>
              <p>
                <Msg id="fast10" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
