import "./Base/index.sass"
import { Header } from "./Header"
import { ThemeMode } from "@/types"
import { isMainPage } from "@/utils"
import classNames from "classnames"
import { Footer } from "./Footer"
import React, { CSSProperties, useEffect } from "react"

interface ILayoutProps extends React.HTMLAttributes<HTMLElement> {
  withHeader?: boolean
  onlyLogo?: boolean
  smallHeader?: boolean
  darkTheme?: boolean
  mb?: number
  footerStyle?: CSSProperties
}
export const Layout = ({
  smallHeader,
  onlyLogo,
  children,
  withHeader = true,
  darkTheme,
  mb,
  footerStyle,
  ...props
}: ILayoutProps) => {
  const themeMode = isMainPage() || darkTheme ? ThemeMode.Dark : ThemeMode.Light
  useEffect(() => {
    const html = document.querySelector("html")
    if (html == null) return
    html.style.backgroundColor = "var(--dark)"
    return () => {
      html.style.background = "inherit"
    }
  }, [])
  return (
    <div className={`main-layout${themeMode}`}>
      <div
        {...props}
        className={classNames("Layout_Wrapper", props.className, {
          MainPage_Header: isMainPage() || darkTheme,
        })}
      >
        {withHeader && (
          <Header smallHeder={smallHeader} onlyLogo={onlyLogo} themeMode={themeMode} />
        )}
      </div>
      <main className="main" style={{ paddingBottom: mb || "initial" }}>
        {children}
      </main>
      <Footer themeMode={themeMode} style={footerStyle} />
    </div>
  )
}
