import { IOption } from "@/types"
import { ApiData } from "@usher/pe-client-front-end-lib"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { BSelect, IBSelectProps } from "../BSelect"
import { Controller, useFormContext, UseControllerOptions } from "react-hook-form"
import { KeyCodes } from "@/constants/KeyCodes"

export const CountrySelect = <T extends string = string>(
  props: Omit<IBSelectProps<T>, "options"> & { name?: string; required?: boolean }
) => {
  const dropdownRef = useRef<HTMLButtonElement | null>(null)
  const listRef = useRef<HTMLUListElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const context = useFormContext()
  const rules: UseControllerOptions["rules"] = {}
  const [options, setOptions] = useState<IOption<string>[]>([])
  const [inputValue, setInputValue] = useState("")
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const fetchCountries = useCallback(async () => {
    const options = (await ApiData.getSelectorList("country"))
      .map((item) => ({
        value: item.value,
        label: item.key,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
    setOptions(options)
  }, [])
  const [filteredOptions, setFilteredOptions] = useState<typeof options>([])

  useEffect(() => {
    setFilteredOptions(options)
  }, [options])

  useEffect(() => {
    fetchCountries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (listRef.current) {
      const dropdownNode = listRef.current as HTMLUListElement
      const highlightedItem = dropdownNode.querySelector(".selected-with-arrow") as HTMLDivElement
      if (highlightedItem) {
        const isBigger = highlightedItem.offsetTop > dropdownNode.clientHeight
        dropdownNode.scrollTop = isBigger
          ? highlightedItem.offsetTop - (dropdownNode.clientHeight - highlightedItem.clientHeight)
          : 0
      }
    }
  }, [selectedIndex])
  if (props.required) rules.required = { message: "This field is required", value: true }
  const error = context.errors[props.name]
  const selectValue = useMemo(() => {
    const fieldValue = context.getValues(props.name)
    const result = options.find((option) => option.value === fieldValue)
    setInputValue(result?.label)
    return result
  }, [context, props.name, options])
  const isError = error != null
  return (
    <Controller
      control={context.control}
      name={props.name}
      rules={rules}
      render={({ onChange }) => (
        <BSelect
          size="large"
          onLoadBtnRef={(ref) => (dropdownRef.current = ref.current)}
          onOpen={() => inputRef.current?.focus()}
          value={selectValue as IOption<T>}
          inputProps={{ placeholder: "Select" }}
          options={filteredOptions as IOption<T>[]}
          onSelect={(option) => {
            setInputValue(option.label)
            onChange(option.value)
            setSelectedIndex(-1)
          }}
          isError={isError}
          listRef={listRef}
          errorText={isError ? error.message : undefined}
          renderLabel={() => {
            return (
              <input
                ref={inputRef}
                type="text"
                style={{ pointerEvents: "initial" }}
                placeholder="Select"
                value={inputValue}
                onKeyDown={(e) => {
                  const isOpened = dropdownRef.current?.classList.contains("show") ?? false
                  if (e.key === KeyCodes.ArrowDown || e.key === KeyCodes.ArrowUp) {
                    e.preventDefault()
                    const isDown = e.key === KeyCodes.ArrowDown
                    setSelectedIndex((old) =>
                      isDown ? Math.min(old + 1, filteredOptions.length - 1) : Math.max(old - 1, 0)
                    )
                    if (!isOpened) dropdownRef.current?.click()
                  }
                  if (e.key === KeyCodes.Enter) {
                    e.preventDefault()
                    setInputValue(filteredOptions[selectedIndex].label)
                    onChange(filteredOptions[selectedIndex].value)
                    document.dispatchEvent(new Event("click"))
                  }
                }}
                onInput={(e) => {
                  const inputValue = (e.target as HTMLInputElement).value
                  setInputValue(inputValue)
                  if (!dropdownRef.current?.classList.contains("show")) dropdownRef.current?.click()
                  if (inputValue.length === 0) setFilteredOptions(options)
                  else
                    setFilteredOptions(
                      options.filter((option) =>
                        option.label.toLowerCase().startsWith(inputValue.toLowerCase().trim())
                      )
                    )
                }}
              />
            )
          }}
          renderOption={(option, index) => (
            <div
              style={{ marginLeft: 15 }}
              className={`${index === selectedIndex ? "selected-with-arrow" : ""}`}
            >
              {option.label}
            </div>
          )}
          {...props}
        />
      )}
    />
  )
}
