import { ReactNode } from "react"
import { FormattedMessage } from "react-intl"

export const Msg = ({
  id,
  vars,
}: {
  id: string
  vars?: Record<string, string | number | ReactNode>
}) => {
  return <FormattedMessage id={id} values={vars} />
}
