import { maskCardNumber, useInformation } from "@usher/pe-client-front-end-lib"
import exchangeIcon from "@/Images/cards_images/exchange.svg"

export const Information = ({ isCreate }: { isCreate?: boolean }) => {
  const { fee, card, cryptoPrice, crypto, cryptoAmount, deposit, fiat } = useInformation()
  return (
    <div className="col-md-6">
      <div
        className="octagon octagon-whit block-table-octagon buy-steps__info"
        style={{ display: "block" }}
      >
        <div className="buy-steps__change-info">
          <div style={{ display: "flex", flexDirection: "column", marginBottom: "35px" }}>
            <span className="buy-steps__from" style={{fontFamily: "ChakraPetch-Semibold"}}>{isCreate ? cryptoPrice : cryptoAmount} </span>
            <span className="buy-steps__name">{crypto}</span>
          </div>
          <img src={exchangeIcon} alt="" className="buy-steps__exchange-ic" />
          <div style={{ display: "flex", flexDirection: "column", marginBottom: "35px" }}>
            <span className="buy-steps__to" style={{fontFamily: "ChakraPetch-Semibold"}}>{deposit}</span>
            <span className="buy-steps__name">{fiat}</span>
          </div>
        </div>
        <div className="buy-steps__info-full">
          {!isCreate && (
            <div className="buy-steps__info-row">
              <span className="buy-steps__info-rate">To card</span>
              <span className="buy-steps__info-val">{maskCardNumber(card?.card_pan)}</span>
            </div>
          )}
          <div className="buy-steps__info-row">
            <span className="buy-steps__info-rate">Amount of transfer</span>
            <span className="buy-steps__info-val">
              {cryptoPrice} {crypto}
            </span>
          </div>
          {!isCreate && (
            <div className="buy-steps__info-row">
              <span className="buy-steps__info-rate">Fee</span>
              <span className="buy-steps__info-val">
                {fee} {crypto}
              </span>
            </div>
          )}
          {!isCreate && (
            <div className="buy-steps__info-row">
              <span className="buy-steps__info-rate">Total amount </span>
              <span className="buy-steps__info-val">
                {cryptoAmount} {crypto}
              </span>
            </div>
          )}
          <div className="buy-steps__info-row">
            <span className="buy-steps__info-rate">
              {isCreate ? "Amount for card issue" : "Amount to be credited"}
            </span>
            <span className="buy-steps__info-val">
              {deposit} {fiat}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
