import { ThemeMode } from "@/types"

interface ISocialIconsProps {
  themeMode: ThemeMode
}
export const SocialIcons = ({ themeMode }: ISocialIconsProps) => {
  const isDark = themeMode === ThemeMode.Dark
  const iconColor = isDark ? "black" : "white"
  return (
    <div className="footer__social">
      <a href="https://facebook.com/wizarbit">
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="26" height="26" rx="13" fill="#7B7B7B" />
          <path
            d="M16.4758 14.4375L16.8906 11.7229H14.2968V9.96123C14.2968 9.21855 14.6591 8.49463 15.8208 8.49463H17V6.1834C17 6.1834 15.9299 6 14.9068 6C12.7707 6 11.3744 7.3002 11.3744 9.65391V11.7229H9V14.4375H11.3744V21H14.2968V14.4375H16.4758Z"
            fill={iconColor}
          />
        </svg>
      </a>
      <a href="https://twitter.com/wizarbit">
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="26" height="26" rx="13" fill="#7B7B7B" />
          <path
            d="M19.3554 10.2398C19.3655 10.382 19.3655 10.5242 19.3655 10.6664C19.3655 15.0031 16.066 20 10.0356 20C8.17767 20 6.45179 19.4617 5 18.5274C5.26397 18.5578 5.51775 18.568 5.79188 18.568C7.32485 18.568 8.73605 18.05 9.86296 17.1664C8.42133 17.1359 7.2132 16.1914 6.79694 14.8914C7.00001 14.9219 7.20304 14.9422 7.41626 14.9422C7.71067 14.9422 8.00511 14.9015 8.2792 14.8305C6.77666 14.5258 5.64972 13.2055 5.64972 11.6109V11.5703C6.08625 11.8141 6.59391 11.9664 7.13195 11.9867C6.24869 11.3976 5.67003 10.3922 5.67003 9.25466C5.67003 8.6453 5.83244 8.08671 6.11672 7.5992C7.73095 9.58983 10.1574 10.8898 12.8782 11.032C12.8274 10.7883 12.7969 10.5344 12.7969 10.2805C12.7969 8.47264 14.2589 7 16.0761 7C17.0203 7 17.8731 7.39609 18.4721 8.03594C19.2132 7.89376 19.9238 7.61952 20.5533 7.24375C20.3096 8.00549 19.7919 8.64533 19.1117 9.05155C19.7716 8.98049 20.4112 8.79764 21 8.54376C20.5534 9.19373 19.995 9.77262 19.3554 10.2398Z"
            fill={iconColor}
          />
        </svg>
      </a>
      <a href="https://instagram.com/wizarbit">
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="26" height="26" rx="13" fill="#7B7B7B" />
          <path
            d="M13.0055 9.41172C11.018 9.41172 9.41484 11.0148 9.41484 13.0023C9.41484 14.9898 11.018 16.593 13.0055 16.593C14.993 16.593 16.5961 14.9898 16.5961 13.0023C16.5961 11.0148 14.993 9.41172 13.0055 9.41172ZM13.0055 15.3367C11.7211 15.3367 10.6711 14.2898 10.6711 13.0023C10.6711 11.7148 11.718 10.668 13.0055 10.668C14.293 10.668 15.3398 11.7148 15.3398 13.0023C15.3398 14.2898 14.2898 15.3367 13.0055 15.3367ZM17.5805 9.26484C17.5805 9.73047 17.2055 10.1023 16.743 10.1023C16.2773 10.1023 15.9055 9.72734 15.9055 9.26484C15.9055 8.80234 16.2805 8.42734 16.743 8.42734C17.2055 8.42734 17.5805 8.80234 17.5805 9.26484ZM19.9586 10.1148C19.9055 8.99297 19.6492 7.99922 18.8273 7.18047C18.0086 6.36172 17.0148 6.10547 15.893 6.04922C14.7367 5.98359 11.2711 5.98359 10.1148 6.04922C8.99609 6.10234 8.00234 6.35859 7.18047 7.17734C6.35859 7.99609 6.10547 8.98984 6.04922 10.1117C5.98359 11.268 5.98359 14.7336 6.04922 15.8898C6.10234 17.0117 6.35859 18.0055 7.18047 18.8242C8.00234 19.643 8.99297 19.8992 10.1148 19.9555C11.2711 20.0211 14.7367 20.0211 15.893 19.9555C17.0148 19.9023 18.0086 19.6461 18.8273 18.8242C19.6461 18.0055 19.9023 17.0117 19.9586 15.8898C20.0242 14.7336 20.0242 11.2711 19.9586 10.1148ZM18.4648 17.1305C18.2211 17.743 17.7492 18.2148 17.1336 18.4617C16.2117 18.8273 14.0242 18.743 13.0055 18.743C11.9867 18.743 9.79609 18.8242 8.87734 18.4617C8.26484 18.218 7.79297 17.7461 7.54609 17.1305C7.18047 16.2086 7.26484 14.0211 7.26484 13.0023C7.26484 11.9836 7.18359 9.79297 7.54609 8.87422C7.78984 8.26172 8.26172 7.78984 8.87734 7.54297C9.79922 7.17734 11.9867 7.26172 13.0055 7.26172C14.0242 7.26172 16.2148 7.18047 17.1336 7.54297C17.7461 7.78672 18.218 8.25859 18.4648 8.87422C18.8305 9.79609 18.7461 11.9836 18.7461 13.0023C18.7461 14.0211 18.8305 16.2117 18.4648 17.1305Z"
            fill={iconColor}
          />
        </svg>
      </a>
    </div>
  )
}
