import { useMemo } from "react"
import { BlogCard } from "./BlogCard"
import { blogs } from "./blogs"

export const RelatedBlogs = () => {
  const randomBlog = useMemo(() => {
    var arr = []
    while (arr.length < 2) {
      var r = Math.floor(Math.random() * 4) + 1
      if (arr.indexOf(r) === -1) arr.push(r)
    }
    return arr.map((index) => blogs.find((blog) => blog.id === String(index)))
  }, [])
  return (
    <div className="related-blogs">
      <p>Related Post</p>
      <div>
        {randomBlog.map(({ content, ...blog }) => (
          <BlogCard {...blog} key={blog.id} />
        ))}
      </div>
    </div>
  )
}
