import React from "react"
import { useFormContext } from "react-hook-form"
import { CtrlInput } from "@/Components/ctrl/Input"
import { Phone } from "./DeliveryAddress"

export const Expense3DSPassword = () => {
  const { watch } = useFormContext()

  return (
    <>
      <label
        className="mb10"
        style={{
          fontSize: "120x",
          lineHeight: "12px",
          fontFamily: "ChakraPetch-SemiBold",
          color: "#777e8a",
        }}
      >
        PIN Code
      </label>
      <CtrlInput
        isPinField
        type="password"
        name="password3ds"
        placeholder="Create PIN Code"
        autoComplete="new-password"
        required
        refProps={{
          minLength: {
            value: 4,
            message: "Minimal length of PIN Code: 4",
          },
        }}
      />
      <div style={{ marginTop: 10 }} />
      <CtrlInput
        isPinField
        type="password"
        name="password3ds_confirm"
        placeholder="Confirm PIN Code"
        autoComplete="new-password"
        required
        refProps={{
          validate: (value: string) => value === watch("password3ds") || `PIN Code didn't match`,
          minLength: {
            value: 4,
            message: "Minimal length of PIN Code: 4",
          },
        }}
      />
      <div style={{ marginTop: 10 }} />
      <div className="Form-InputBox Form-InputBox--Dark">
        <span
          style={{
            fontSize: "12px",
            lineHeight: "16px",
            fontFamily: "ChakraPetch-SemiBold",
            color: "#777e8a",
          }}
        >
          Mobile
        </span>
        <Phone />
      </div>
    </>
  )
}
