export const CardStatus = ({
  status,
  onClick,
  className,
}: {
  status: string
  className?: string
  onClick?: () => void
}) => {
  const classname =
    className ?? (status.includes(" ") ? status.replace(" ", "_") : status).toLowerCase()
  return (
    <span onClick={onClick} className={`card_status_label ${classname}`}>
      {status.toLowerCase()}
    </span>
  )
}
