import React from "react"
import ReactTooltip from "react-tooltip"
import { Msg } from "./Msg"

type HowCalculatedProps = {
  text?: string
}

export function HowCalculated({ text }: HowCalculatedProps) {
  return (
    <>
      <span data-tip="" data-for="how-calculated" data-event="click focus">
        {text}
      </span>
      <ReactTooltip
        id="how-calculated"
        className="TooltipSimple"
        globalEventOff="click"
        place="bottom"
        effect="solid"
        arrowColor="#5BA1FF"
        textColor="#fff"
        backgroundColor="#5BA1FF"
        offset={{ left: 105 }}
      >
        <h4>
          <Msg id="p9" />
        </h4>
        <p>
          <Msg id="p8" />
        </p>
      </ReactTooltip>
    </>
  )
}
