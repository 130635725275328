import { ReactNode } from "react"
import "./style.css"

interface IPaperProps extends React.HTMLAttributes<HTMLElement> {
  children: ReactNode
  padding?: string
}
export const Paper = ({ className, padding, ...props }: IPaperProps) => {
  return (
    <div {...props} className={`paper ${className ?? ""}`} style={{ padding, ...props.style }} />
  )
}
