import { Link, useHistory } from "react-router-dom"
import { useLoginForm } from "@usher/pe-client-front-end-lib"
import { Button } from "@/Components/Control/Button"
import { Input } from "@/Components/Form/Input"
import { Text } from "@/Components/Text"
import { Container } from "@/Layout/Container"
import { Paper } from "@/Layout/Paper"
import { Stack } from "@/Layout/Stack"
import "./style.css"
import { FormWrapper } from "@/Components/Form"
import { gtag } from "@/Helpers/gTag"
import { Msg } from "@/Components/Msg"
import { useIntl } from "react-intl"

export const Login = () => {
  const intl = useIntl()
  const { formMethods, onSubmit, loading } = useLoginForm({ history: useHistory() })
  return (
    <div className="login_wrapper">
      <Container>
        <div className="login_form_head">
          <Text>
            <Msg id="login" />
          </Text>
          <Text variant="subtitle">
            <Msg id="no_account" />?{" "}
            <Link to="/register">
              {" "}
              <Msg id="register_step" />
            </Link>
          </Text>
        </div>
        <FormWrapper
          methods={formMethods}
          onSubmit={(data) => {
            gtag("event", "login")()
            onSubmit(data)
          }}
        >
          <Paper>
            <Stack>
              <Input
                isEmail
                name="email"
                required
                label={intl.formatMessage({ id: "your_email" })}
              />
              <Input
                type="password"
                name="password"
                required
                label={intl.formatMessage({ id: "pass" })}
              />
            </Stack>

            <Text to="/recovery">
              <Msg id="forgot" />?
            </Text>
            <div style={{ marginTop: 54 }}>
              <Button loading={loading} type="submit">
                <Msg id="login" />
              </Button>
            </div>
          </Paper>
        </FormWrapper>
      </Container>
    </div>
  )
}
