import { HTMLAttributes, ReactNode, useEffect, useMemo, useState } from "react"
import "./style.sass"
import { IOption } from "@/types"
import { autoUpdate, flip, offset, size, useFloating } from "@floating-ui/react"
import { DropdownIcon } from "./DropdownIcon"
import clsx from "clsx"
import { ClickAwayListener } from "../ClickAwayListener"

interface ISelectorProps {
  options: IOption[]
  value?: IOption | string
  onChange?: (value: IOption) => void
  classNames?: Partial<{
    control: string
    list: string
  }>
  renderOptions?: (
    option: IOption,
    props: HTMLAttributes<HTMLDivElement> & { key: any }
  ) => ReactNode
  leftSection?: ((value: string) => ReactNode) | ReactNode
  rightSection?: ReactNode
  fullWidth?: boolean
  variant?: "outlined" | "text" | "contained"
  searchable?: boolean
}

export const Selector = ({
  renderOptions,
  leftSection,
  rightSection,
  value,
  options,
  fullWidth,
  onChange,
  classNames,
  variant = "text",
  searchable,
}: ISelectorProps) => {
  const [inputValue, setInputValue] = useState("")
  const [open, setOpen] = useState(false)
  const [filteredOptions, setFilteredOptions] = useState<IOption[]>(options)
  const { refs, floatingStyles, placement } = useFloating({
    placement: "bottom-start",
    open: open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
          })
        },
        padding: 10,
      }),
    ],
  })
  const close = () => setOpen(false)
  const selectValue = useMemo(() => {
    if (typeof value === "object")
      return options?.find((option) => option.value === value.value)?.label ?? ""
    return options?.find((option) => option.value === value)?.label ?? ""
  }, [options, value])
  useEffect(() => {
    setFilteredOptions(options)
  }, [options])
  useEffect(() => {
    if (searchable && variant !== "text") {
      if (typeof value === "object") setInputValue(value?.label)
      else setInputValue(value ?? "")
    }
  }, [value, searchable, variant])
  return (
    <ClickAwayListener onClickAway={close}>
      <div
        ref={refs.setReference}
        className={clsx(
          `selector-custom selector-custom__${variant}`,
          classNames?.control,
          fullWidth && "selector-custom__full-width"
        )}
        onClick={() => setOpen(!open)}
      >
        <div style={leftSection != null ? { marginLeft: -10 } : {}}>
          {typeof leftSection === "function" ? leftSection(selectValue) : leftSection}
          {searchable && variant !== "text" ? (
            <input
              className="flex-1"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onInput={(e) => {
                if (!open) setOpen(true)
                return setFilteredOptions(
                  options.filter((opt) =>
                    opt.label.toLowerCase().startsWith(e.currentTarget.value.toLowerCase())
                  )
                )
              }}
            />
          ) : (
            <span>{selectValue}</span>
          )}
        </div>
        {rightSection}
        <div className="dropdown-icon">
          <DropdownIcon up={open} />
        </div>
      </div>
      {open && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className={clsx(
            `select-custom__list ${classNames?.list ?? ""} h-fit overflow-hidden flex flex-col`,
            placement.startsWith("top") && "flex-col-reverse"
          )}
        >
          {searchable && variant === "text" && (
            <div>
              <input
                className="text-sm flex-1 text-black w-full px-[20px] py-2.5 border-b"
                placeholder="Search..."
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onInput={(e) => {
                  if (!open) setOpen(true)
                  return setFilteredOptions(
                    options.filter((opt) =>
                      opt.label.toLowerCase().startsWith(e.currentTarget.value.toLowerCase())
                    )
                  )
                }}
              />
            </div>
          )}
          <div className="select-custom__list-wrapper overflow-y-auto">
            {filteredOptions.map((option, index) =>
              renderOptions != null ? (
                renderOptions(option, {
                  className: clsx(
                    "select-custom__list-item whitespace-nowrap",
                    variant === "text" ? "min-w-[200px]" : "min-w-[120px]",
                    option.label === selectValue && "selector-custom__selected"
                  ),
                  key: index,
                  onClick: () => {
                    onChange?.(option)
                    close()
                  },
                })
              ) : (
                <div
                  className={clsx(
                    "select-custom__list-item whitespace-nowrap",
                    variant === "text" ? "min-w-[200px]" : "min-w-[120px]",
                    option.label === selectValue && "selector-custom__selected"
                  )}
                  key={index}
                  onClick={() => {
                    onChange?.(option)
                    close()
                  }}
                >
                  {option.label}
                </div>
              )
            )}
          </div>
        </div>
      )}
    </ClickAwayListener>
  )
}
