import "./style.sass";

interface ICustomStepperProps {
  steps: string[];
  currentStep: number;
}

export const CustomStepper = ({ steps, currentStep }: ICustomStepperProps) => {
  return (
    <div className="custom_stepper">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`stepper-item ${currentStep >= index + 1 ? "active" : ""}`}
        >
          <span>{index + 1}</span>
          <span>{step}</span>
        </div>
      ))}
    </div>
  );
};
