import { Layout } from "@/Layout"
import "./style.css"
import { LimitBlock } from "./LimitBlock"
import { Msg } from "@/Components/Msg"

export const LIMITS_ITEMS = ["BTC", "ETH", "LTC", "USDT", "USDT-TRC20"]
export const Limits = () => {
  return (
    <Layout darkTheme>
      <main className="main rates-limits">
        <section className="rates">
          <div className="container">
            <div className="rates__wrap">
              <h1 className="h2 rates__title">
                <Msg id="limits1" />
              </h1>
              <div className="rates-label"></div>
              <div className="rates-list">
                <div className="rates-listItem">
                  <span>Fiat to Crypto</span>
                  {LIMITS_ITEMS.map((limit) => (
                    <LimitBlock limit={limit} left="EUR" />
                  ))}
                </div>
                <div className="rates-listItem">
                  <span>Crypto to Fiat</span>
                  {LIMITS_ITEMS.map((limit) => (
                    <LimitBlock limit="EUR" left={limit} />
                  ))}
                </div>
              </div>
              <div style={{ marginTop: 16 }} className="rates-list-item">
                <p>
                  <Msg id="limits2" />
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}
