import { useStoreCardView, useCardNumberAndCvv } from "@usher/pe-client-front-end-lib"

export const CardDetailsCard = () => {
  const { expiryDate } = useStoreCardView((state) => state.card)
  const { cardNumber, cardCvv, toggleAll, cardClosed, panToggled, cvvToggled } =
    useCardNumberAndCvv()
  const isToggled = panToggled && cvvToggled
  return (
    <div className="block-one-card_visual">
      <div className="block-one-card_visual_top">
        {!cardClosed && (
          <div
            className={`block-one-card_visual_view${!isToggled ? " no-view" : ""}`}
            onClick={toggleAll}
          />
        )}
      </div>
      <div className="block-one-card_visual_number-card">{cardNumber}</div>
      <div className="block-one-card_visual_bottom">
        <div className="block-one-card_visual_info">
          <div className="block-one-card_visual_label">VALID THRU</div>
          <div className="block-one-card_visual_value">{expiryDate}</div>
        </div>
        <div className="block-one-card_visual_info">
          <div className="block-one-card_visual_label">CVV</div>
          <div className="block-one-card_visual_value">{cardCvv}</div>
        </div>
      </div>
    </div>
  )
}
