import { Checkbox } from "@/Components/Form/Checkbox"
import { Msg } from "@/Components/Msg"
import { GetLink } from "@/utils"
import { useIntl } from "react-intl"

export const AgreeTermCheck = ({
  name = "agree_terms",
  onlyPrivacy = false,
}: {
  onlyPrivacy?: boolean
  name?: string
}) => {
  const intl = useIntl()
  return (
    <Checkbox
      name={name}
      required
      label={
        <span>
          <Msg id="accept" />{" "}
          {!onlyPrivacy && (
            <>{GetLink("terms-of-use", intl.formatMessage({ id: "footer8" }))} and </>
          )}
          {GetLink("privacy-policy", intl.formatMessage({ id: "privacy_title" }))}
        </span>
      }
    />
  )
}

export const AgreeRiskCheck = () => {
  const intl = useIntl()
  return (
    <Checkbox
      name="agree_risk"
      required
      label={
        <span>
          <Msg id="accept" />{" "}
          {GetLink("risk-disclosure", intl.formatMessage({ id: "risk_nav_title" }))}
        </span>
      }
    />
  )
}

export const AgreeRefundCheck = () => {
  const intl = useIntl()
  return (
    <Checkbox
      name="agree_refund"
      required
      label={
        <span>
          <Msg id="accept" /> {GetLink("refund-policy", intl.formatMessage({ id: "footer9" }))}
        </span>
      }
    />
  )
}

export const AgreeAgeCheck = () => {
  return (
    <Checkbox
      name="agree_age"
      required
      label={
        <span>
          <Msg id="old18" />
        </span>
      }
    />
  )
}
