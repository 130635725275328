import { ArrowButton } from "@/Components/Control/Button"
import { Image } from "@/Components/common/Image"
import { useHistory } from "react-router-dom"

interface Props {
  title: string
  subTitle: string
  cover: string
  id: string
}
export const BlogCard = ({ cover, subTitle, title, id }: Props) => {
  const histroy = useHistory()
  return (
    <div
      className="blog-card"
      style={{ cursor: "pointer" }}
      onClick={() => histroy.push(`/blog/view/${id}`)}
    >
      <div className="blog-card__header">
        <Image
          src={`blog/${cover}.png`}
          style={{
            borderRadius: 20,
          }}
        />
      </div>
      <div className="blog-card__title">{title}</div>
      <div className="blog-card__min-content">{subTitle}</div>
      <div className="blog-card__bottom">
        <span>5 July 2024</span>
        <ArrowButton title="Read More" to={`/blog/view/${id}`} />
      </div>
    </div>
  )
}
