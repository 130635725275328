import { IFaqList } from "./list"
import "./style.sass"
import { FaqPaper } from "./components/FaqPaper"
import { Msg } from "@/Components/Msg"
import { FaqHeader } from "./components/FaqHeader"

interface IFaqProps {
  list: IFaqList[]
}
export const FaqMain = ({ list }: IFaqProps) => {
  return (
    <>
      <FaqHeader title="FAQ" />
      <div className="faq__content">
        {list.map((faq_item, index) => (
          <FaqPaper
            key={index}
            prefix={faq_item.prefix}
            title={<Msg id={faq_item.title} />}
            list={faq_item.list}
          />
        ))}
      </div>
    </>
  )
}
