import { Image } from "@/Components/common/Image"
import "./style.css"
import { Msg } from "@/Components/Msg"

export const HowToBuy = () => {
  return (
    <section className="home_section buy">
      <div className="container">
        <div className="buy__row">
          <div className="buy__text">
            <h2 className="h2 by__h2">
              <Msg id="how1" />
            </h2>
            <ul className="buy__list">
              <li>
                <Image src="by-1.png" alt="" />
                <div className="buy__list-box">
                  <h3 className="buy__list-title">
                    <Msg id="register_step" />
                  </h3>
                  <p className="buy__list-describe">
                    <Msg id="sign1" />
                  </p>
                </div>
              </li>
              <li>
                <Image src="by-2.png" alt="" />
                <div className="buy__list-box by__list-box--two">
                  <h3 className="buy__list-title">
                    <Msg id="sign2" />
                  </h3>
                  <p className="buy__list-describe">
                    <Msg id="sign3" />
                  </p>
                </div>
              </li>
              <li>
                <Image src="by-3.png" alt="" />
                <div className="buy__list-box">
                  <h3 className="buy__list-title">
                    <Msg id="buy_step" />
                  </h3>
                  <p className="buy__list-describe">
                    <Msg id="buy1" />
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}
