import React from "react"
import { useStoreCardView } from "@usher/pe-client-front-end-lib"
import { CardActionName } from "./Actions/Name"

export const CardDetailsDetails = () => {
  const { expiryDate, status, embossingName, type } = useStoreCardView((state) => state.card)

  return (
    <div className="block-one-card_details">
      <div className="block-one-card_info-title">Details</div>
      <DetailItem label="Card name" value={<CardActionName />} />
      <DetailItem label="Status" value={status} />
      <DetailItem label="Cardholder" value={embossingName || "N/A"} />
      <DetailItem label="Type" value={type === "1" ? "Virtual" : "Physical"} />
      <DetailItem label="Expiration date" value={expiryDate} />
    </div>
  );
};

const DetailItem = ({ label, value }: { label: string; value: string | JSX.Element }) => {
  return (
    <div className="block-one-card_info-item">
      <div className="block-one-card_info-label">{label}</div>
      <div className="block-one-card_info-value">{value}</div>
    </div>
  );
};
