import { Layout } from "@/Layout"
import { Container } from "@/Layout/Container"
import { BlogCard } from "./BlogCard"
import "./style.sass"
import { Header } from "./Header"
import { blogs } from "./blogs"
import { Pagination } from "./Pagination"

export const Blogs = () => {
  return (
    <Layout className="blogs">
      <Container className="blogs-container">
        <Header />
        <div className="blogs__grid">
          {blogs.map((blog) => (
            <BlogCard id={blog.id} cover={blog.cover} subTitle={blog.subTitle} title={blog.title} />
          ))}
        </div>
        <Pagination total={4} page={0} perPage={4} />
      </Container>
    </Layout>
  )
}
