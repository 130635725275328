import { Layout } from "@/Layout"
import "./style.css"
import { Button } from "@/Components/Control/Button"
import {
  VALID_EMAIL,
  VALID_NAME,
  VALID_PHONE,
  useAffiliateForm,
} from "@usher/pe-client-front-end-lib"
import { FormWrapper } from "@/Components/Form"
import { Input } from "@/Components/Form/Input"
import { CountrySelect } from "@/NewComponents/CountrySelect"
import { Stack } from "@/Layout/Stack"
import { CtrlReCaptcha } from "@/Components/ctrl/ReCaptcha"
import { scrollTo } from "@/utils"

export const ReferalProgram = () => {
  const { formMethods, onSubmit, captchaRef, onCaptchaChange } = useAffiliateForm()
  return (
    <Layout darkTheme smallHeader>
      <main className="main referal-main">
        <section className="refer">
          <div className="container">
            <div className="refer__wrap">
              <h1 className="h2 refer__title">join our referral program</h1>
              <div className="refer__slogan">Start earning money by inviting friends</div>
              <Button variant="primary" onClick={() => scrollTo("join")}>
                Join us
              </Button>
            </div>
          </div>
        </section>

        <section className="sing-up sing-up__referal" id="sing-up">
          <div id="join" className="container">
            <div className="sing-up__wrap">
              <h2 className="h2 sing-up-title">Join now & start making money today with us</h2>
              <div className="sing-up-form">
                <h3 className="sing-up-form__title">Contact details</h3>
                <FormWrapper methods={formMethods} onSubmit={onSubmit}>
                  <div className="sing-up__box-label">
                    <Input dark name="first_name" label="First Name" required rules={VALID_NAME} />
                    <Input dark name="last_name" label="Last Name" required />
                  </div>

                  <div className="sing-up__box-label">
                    <Input
                      dark
                      name="email"
                      isEmail
                      type="email"
                      rules={VALID_EMAIL}
                      required
                      label="Your Email (Used for Communications)"
                    />
                    <Input dark name="skype" label="Skype" />
                  </div>

                  <Stack mb={55}>
                    <CountrySelect
                      isDark
                      label="Country of Residence"
                      name="country_code"
                      required
                    />
                    <Input
                      dark
                      label="Phone Number"
                      name="phone"
                      rules={{ ...VALID_PHONE, minLength: undefined, maxLength: undefined }}
                      required
                    />
                  </Stack>
                  <h3 className="sing-up-form__title">Tell us about your traffic</h3>
                  <div className="sing-up__box-label sing-up__box-margin">
                    <Input dark name="website" label="Website URL" required />
                    <Input
                      dark
                      name="visitors_count"
                      label="Current monthly unique visitors"
                      required
                    />
                  </div>

                  <div className="captcha">
                    <CtrlReCaptcha refObj={captchaRef as never} onChange={onCaptchaChange} />
                  </div>
                  <Button type="submit">Join Us</Button>
                </FormWrapper>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}
