import "./index.sass"
import ReactModal from "react-modal"
import { IconClose } from "../../Images/Icons"
import classnames from "classnames"
import { ReactNode } from "react"

interface IModalProps {
  closable?: boolean
  isOpen: boolean
  children: ReactNode
  handleClose?: VoidFunction
  size: string | number
}
export const Modal = ({ closable = true, isOpen, children, handleClose, size }: IModalProps) => {
  return (
    <div>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={handleClose}
        className={classnames("Modal", "Modal--Dialog", {
          [`Modal--${size}`]: size,
        })}
        overlayClassName={"Modal__Overlay"}
        ariaHideApp={false}
        bodyOpenClassName={"modal-open"}
      >
        {closable && <IconClose onClick={handleClose} className="Modal__Close" />}
        {children}
      </ReactModal>
    </div>
  )
}

interface IDialogProps extends IModalProps {
  title?: string
}
export const Dialog = ({ isOpen, children, size, closable, handleClose, title }: IDialogProps) => {
  if (!isOpen) return null
  return (
    <div className="dialog_wrapper">
      <ReactModal
        isOpen={isOpen}
        onRequestClose={handleClose}
        className={classnames("Modal", "CustomDialog", {
          [`Modal--${size}`]: size,
        })}
        overlayClassName={"Modal__Overlay"}
        ariaHideApp={false}
        bodyOpenClassName={"modal-open"}
      >
        <div className="CustomDialog--title">
          <span>{title}</span>
          {closable && <IconClose onClick={handleClose} className="CustomDialog--close_icon" />}
        </div>
        {children}
      </ReactModal>
    </div>
  )
}
