import { match, useHistory } from "react-router-dom"
import { IFaqList } from "./list"
import { FaqHeader } from "./components/FaqHeader"
import { Msg } from "@/Components/Msg"

interface IFaqListProps {
  data: IFaqList
  match: match<{ [x: string]: string }>
}
export const FaqList = ({ data, match }: IFaqListProps) => {
  const history = useHistory()
  return (
    <div className="faq-list">
      <FaqHeader title={<Msg id={data.title} />} back="/faq" />
      {data.list.map((faq_item) => {
        if (faq_item.hidden) return null
        return (
          <div
            key={faq_item.id}
            className="faq__list-paper"
            onClick={() => history.push(`${match.url}/${faq_item.redirect ?? faq_item.id}`)}
          >
            <div>
              <div className="faq-title">
                <Msg id={faq_item.title} />
              </div>
              {faq_item.date != null && <div className="faq-content__date">{faq_item.date}</div>}
            </div>
            <div className="faq__view-content">{faq_item.content}</div>
          </div>
        )
      })}
    </div>
  )
}
