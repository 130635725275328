export const STEPS = [
  {
    name: "Top-Up Card By Crypto",
    path: "by-crypto",
  },
  {
    name: "Payment Confirmation",
    path: "confirmation",
  },
  {
    name: "Bitcoin Instruction",
    path: "instruction",
  },
  {
    name: "Transaction Status",
    path: "status",
  },
]

const getStepClass = (currentStep: number, step: number) => {
  if (step === currentStep) return "active"
  if (step < currentStep) return "done"
  if (step + 1 === currentStep) return "next"
  return ""
}

export const Stepper = ({ currentStep }: { currentStep: number }) => {
  return (
    <ul className="buy-steps__line list">
      {STEPS.map((step, index) => (
        <li key={index} className={`buy-steps__line-item ${getStepClass(currentStep, index + 1)}`}>
          <span>{step.name}</span>
        </li>
      ))}
    </ul>
  )
}
