import { Link } from "react-router-dom"
import { TransactionsFilter } from "../../Transactions/Filter"
import { CardTable } from "./CardTable"
import "./index.sass"
import { ICardTransactionItem, useCardTransactions } from "@usher/pe-client-front-end-lib"
import { TransactionsPager } from "../../Transactions/Pager"
import { PAGE_SIZE } from "../../Transactions"

export interface ICard {
  transactions: ICardTransactionItem[]
}

export const Card = ({
  withoutTab = false,
  widgetView = false,
}: {
  withoutTab?: boolean
  widgetView?: boolean
}) => {
  const {
    data,
    isCardPage,
    dataTotal,
    pageCount,
    path,
    setFilter,
    setPage,
    page,
    fetchStatement,
    loading,
  } = useCardTransactions({ page_size: PAGE_SIZE })
  return (
    <>
      <div className="main-content_content">
        {!withoutTab && (
          <div className="main-content_left">
            <div className="div-h1">Transaction History</div>
            <div className="main-content_btn">
              <Link
                to="/client/exchange"
                className={`btn-reset btn-sq btn-long no-decorate ${!isCardPage ? "btn-blue" : ""}`}
              >
                Exchange
              </Link>
              <Link
                to={`${path}/card`}
                className={`btn-reset btn-sq no-decorate ${isCardPage ? "btn-blue" : ""}`}
              >
                CARD
              </Link>
            </div>
          </div>
        )}
        {!widgetView && (
          <TransactionsFilter compact type="Card" onFilter={setFilter} withoutExchangeTo />
        )}
        <CardTable
          setFilter={widgetView ? setFilter : null}
          data={data}
          onDownloadClick={fetchStatement}
          downloadLoading={loading}
        />
        <div style={{margin: "0 30px 30px 30px"}}>
        <TransactionsPager
          pageSize={PAGE_SIZE}
          dataTotal={dataTotal}
          pageCount={pageCount}
          pageIndex={page}
          gotoPage={(page: number) => setPage(page)}
          nextPage={() => setPage((old) => (old < pageCount ? old + 1 : old))}
          previousPage={() => setPage((old) => (old > 0 ? old - 1 : old))}
          canPreviousPage={page > 0}
          canNextPage={page < pageCount - 1}
        />
        </div>
      </div>
    </>
  )
}
