import { Register } from "@/Pages/Auth/Register"

export const CardRegister = () => {
  return (
    <section className="sing-up" id="sing-up">
      <div className="container">
        <div className="sing-up__wrap">
          <h2 className="h2 sing-up__title">Sign Up</h2>
          <div className="sing-up-form">
            <Register isForCard />
          </div>
        </div>
      </div>
    </section>
  )
}
