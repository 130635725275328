import "./sprite.sass"
import { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { FormProvider } from "react-hook-form"
import { CtrlSelect } from "@/Components/ctrl/Select"
import { CtrlInput } from "@/Components/ctrl/Input"
import { useCardCreate } from "@usher/pe-client-front-end-lib"
import { ExpensePinCode } from "./PinCode"
import { ExpenseDeliveryAddress } from "./DeliveryAddress"
import { Expense3DSPassword } from "./3DSPassword"
import { gtag } from "@/Helpers/gTag"
import { isWidgetPage } from "@/utils"

export const CreateForm = () => {
  const history = useHistory()
  const [accepted, setAccepted] = useState(false)
  const [useAccepted, setUseAccepted] = useState(false)
  const [isUs, setIsUs] = useState(false)

  const onSuccess = (type: string) => {
    const url = isWidgetPage()
      ? "/widgets/cards/order-card/select-payment"
      : "/cards/order-card/expense/select-payment"
    gtag("event", type)()
    history.push(url)
  }
  const { onSubmit, formMethods, isVirtual, cardTypes, isAllExist } = useCardCreate({
    onSuccess,
  })

  const handleSubmit = (values: any) => {
    formMethods.setValue("countryCode", values.countryCode?.value)
    formMethods.setValue("phoneCode", null)
    onSubmit({ ...values, countryCode: values.countryCode?.value?.value, phoneCode: null })
  }

  return (
    <>
      <div className="main-form-cards_card fit-content">
        <>
          <div
            style={{
              fontSize: "20px",
              lineHeight: "26px",
              textAlign: "center",
              marginBottom: "30px",
              fontFamily: "ChakraPetch-Regular",
            }}
          >
            <p style={{ marginBottom: "10px" }}>Expenses Card</p>
            <p
              style={{
                fontSize: "12px",
                lineHeight: "16px",
                fontFamily: "ChakraPetch-SemiBold",
                fontWeight: 600,
                color: "#777e8a",
              }}
            >
              Mastercard for your expenses
            </p>
          </div>

          <FormProvider {...formMethods}>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                if (accepted && useAccepted && isUs) {
                  formMethods.handleSubmit(handleSubmit)()
                }
              }}
              className="main-form-cards_form"
            >
              <div className="mb40">
                <CtrlInput
                  name="username"
                  className="mb10 disabled"
                  placeholder="User Name"
                  disabled
                />
                <CtrlInput
                  name="nickname"
                  className="mb10"
                  placeholder="Card Nickname (optional)"
                />
                <div className="Form-InputBox Form-InputBox--Dark">
                  <div
                    className={`select-form select-card mb10 ${
                      isVirtual ? "select-card-green" : ""
                    }`}
                  >
                    <CtrlSelect disabled={isAllExist} name="type" options={cardTypes} />
                  </div>
                </div>
              </div>
              <div>
                {!isVirtual && (
                  <>
                    <ExpensePinCode />
                    <ExpenseDeliveryAddress />
                    {/* <ExpenseDeliveryOptions options={deliveryTypes} /> */}
                  </>
                )}
                {isVirtual && <Expense3DSPassword />}
              </div>
              <div onClick={() => setAccepted(!accepted)}>
                <input type="checkbox" className="input-checkbox" checked={accepted} readOnly />
                <label className="label-checkbox mb15">
                  <span>
                    I accept{" "}
                    <a
                      style={{ display: "inline", color: "#3F48CC" }}
                      onClick={(e) => e.stopPropagation()}
                      href="/legal/card-terms"
                      target="_blank"
                    >
                      Card Terms and Conditions
                    </a>{" "}
                    and{" "}
                    <a
                      style={{ color: "#3F48CC", display: "inline" }}
                      onClick={(e) => e.stopPropagation()}
                      href="/legal/card-framework"
                      target="_blank"
                    >
                      Card Framework Agreement
                    </a>
                  </span>
                </label>
              </div>
              <div onClick={() => setUseAccepted(!useAccepted)}>
                <input type="checkbox" className="input-checkbox" checked={useAccepted} readOnly />
                <label className="label-checkbox mb15">
                  <span>
                    I agree with{" "}
                    <a
                      style={{ display: "inline", color: "#3F48CC" }}
                      onClick={(e) => e.stopPropagation()}
                      href="/legal/card-use-terms"
                      target="_blank"
                    >
                      Wizarbit's Terms of use for cards
                    </a>
                  </span>
                </label>
              </div>
              <div style={{ marginBottom: 40 }} onClick={() => setIsUs(!isUs)}>
                <input type="checkbox" className="input-checkbox" checked={isUs} readOnly />
                <label className="label-checkbox mb15">
                  <span>I am not a US citizen or resident</span>
                </label>
              </div>
              <div className="d-flex actions gap-20">
                <Link
                  to={isWidgetPage ? "/widgets/cards" : "/cards"}
                  className="btn-sq flex-1 btn-go-back"
                >
                  Go Back
                </Link>
                {!isAllExist && (
                  <input
                    type="submit"
                    value="Next"
                    className={`btn-reset btn-sq btn-blue-dark no-decorate flex-1 ${
                      accepted && useAccepted && isUs ? "" : "btn-disabled"
                    }`}
                  />
                )}
              </div>
            </form>
          </FormProvider>
        </>
      </div>
    </>
  )
}
