import { ReactNode } from "react"

interface IFooterTitleProps {
  isDark: boolean
  title?: ReactNode
}
export const Title = ({ title, isDark }: IFooterTitleProps) => {
  return (
    <h3 className={`footer__link-title ${!isDark ? "footer-light__link-title" : ""}`}>{title}</h3>
  )
}
