import { Layout } from "@/Layout"
import "./style.css"
import { CardHero } from "./CardHero"
import { Cards } from "./Cards"
import { Access } from "./Access"
import { Purch } from "./Purch"
import { HowWorks } from "./HowWorks"
import { CardFaq } from "./CardFaq"
import { CardRegister } from "./Register"

export const Card = () => {
  return (
    <Layout darkTheme withHeader smallHeader>
      <div className="card__page-wrapper">
        <CardHero />
        <Cards />
        <Access />
        <Purch />
        <HowWorks />
        <CardFaq />
        <CardRegister />
      </div>
    </Layout>
  )
}
