import React from 'react'
import classnames from 'classnames'
import './index.sass'

const getVisiblePages = (pageCount, pageIndex) => {
  const navPages = 3

  return Array.from({ length: pageCount }, (x, i) => i)
    .slice(Math.max(0, pageIndex - navPages), pageIndex + navPages)
}

export const TransactionsPager = ({
  pageSize,
  dataTotal,
  pageCount,
  pageIndex,
  gotoPage,
  nextPage,
  canNextPage,
  previousPage,
  canPreviousPage
}) => {
  if (pageCount === 0)
    return null

  return (
    <div className="TablePagination">
      <div className="TablePagination__Count">
        Showing {pageIndex * pageSize + 1} to {(pageIndex + 1) * pageSize} of {dataTotal} entries
      </div>
      <div className="TablePagination__Controls">
        {canPreviousPage && <>
          <div className="TablePagination__Item" onClick={() => gotoPage(0)}>&lt;&lt;</div>
          <div className="TablePagination__Item" onClick={previousPage}>&lt;</div>
        </>}

        {getVisiblePages(pageCount, pageIndex).map(page =>
          <div key={page} onClick={() => gotoPage(page)} className={classnames('TablePagination__Item', {
            'TablePagination__Item--Active': (pageIndex === page)
          })}>{page + 1}</div>
        )}

        {canNextPage && <>
          <div className="TablePagination__Item" onClick={nextPage}>&hellip;</div>
          <div className="TablePagination__Item" onClick={() => gotoPage(pageCount - 1)}>Next &gt;</div>
        </>}
      </div>
    </div>
  )
}
