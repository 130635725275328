import React from "react"
import { FormProvider } from "react-hook-form"
import { Image } from "@/Components/common/Image"
import { Modal } from "@/Components/Layout/Modal"
import { useCardPassword } from "@usher/pe-client-front-end-lib"
import { closeModal } from "@/Helpers/ModalHelper"
import { Expense3DSPassword } from "@/Pages/Dashboard/Components/Cards/Create/Expense/3DSPassword"

export const CardActionPassword = () => {
  const { fetching, changePINCode, formMethods } = useCardPassword({
    onSuccess: () => closeModal("change-password"),
  })
  return (
    <>
      <button className="btn-modal" data-bs-target="#change-password" data-bs-toggle="modal">
        <Image src="edit_pin.svg" />
        Change PIN Code
      </button>
      <Modal id="change-password" title="Change PIN Code">
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(changePINCode)}>
            <Expense3DSPassword />
            <button
              type="submit"
              className="btn-reset btn-sq btn-long btn-blue-dark"
              disabled={fetching}
            >
              Submit
            </button>
          </form>
        </FormProvider>
      </Modal>
    </>
  )
}
