import { BSelect } from "@/NewComponents/BSelect"
import { Image } from "@/Components/common/Image"
import { useState } from "react"
import { makeOption } from "@/utils"
import { IOption } from "@/types"
import { LIMITS_ITEMS } from "."

const getOptions = (data: string) => {
  return makeOption([...(data !== "EUR" ? ["EUR"] : LIMITS_ITEMS)].filter((o) => data !== o))
}

const getIcon = (name: string) => {
  if (name?.startsWith("USDT")) return "usdt"
  return name?.toLowerCase()
}

interface ILimitBlockProps {
  limit: string
  left?: string
}
export const LimitBlock = ({ limit, left }: ILimitBlockProps) => {
  const [selected, setSelected] = useState<IOption>({ label: limit, value: limit })
  return (
    <div className="rates-list__item">
      <div className="rates-list__box">
        <Image src={`${getIcon(left)}-dark.svg`} alt={left} />
        <div className="rates-list__block">
          <p>{left}</p>
        </div>
      </div>
      <div className="rates-list__per">5%</div>
      <div className="rates-list__box">
        <BSelect
          buttonStyle={{ minWidth: left !== "EUR" ? "fit-content:" : 180 }}
          renderOption={(value) => (
            <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
              <Image src={`${getIcon(value.label)}-dark.svg`} alt={value.label} />
              {value.label}
            </div>
          )}
          renderLabel={(value) => (
            <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
              <Image src={`${getIcon(value?.label)}-dark.svg`} alt={value?.label} />
              {value?.label}
            </div>
          )}
          options={getOptions(left)}
          onSelect={(value) => setSelected(value)}
          isDark
          value={selected}
        />
      </div>
    </div>
  )
}
