import ReactSelect, { components, StylesConfig } from "react-select"
import "./style.sass"
import { DropdownIcon } from "./DropdownIcon"
import { AutocompleteConfig } from "./config"
import { IOption } from "@/types"
import { ReactNode, RefObject } from "react"

interface AutocompleteProps {
  size?: "sm" | "md"
  options: IOption[]
  value?: IOption
  error?: string
  inputRef?: RefObject<any>
  onChange?: (value: IOption | null) => void
  renderOption?: (value: IOption | null) => ReactNode
  label?: string
  styles?: StylesConfig<IOption>
  searchable?: boolean
  emptyMessage?: string
  mode?: "text" | "default"
}
export const Autocomplete = ({
  size = "md",
  options,
  value,
  inputRef,
  onChange,
  renderOption,
  label,
  styles,
  searchable = false,
  emptyMessage,
  error,
}: AutocompleteProps) => {
  return (
    <div>
      <ReactSelect
        ref={inputRef}
        value={value ?? null}
        placeholder={label}
        onChange={(value) => onChange?.(value as any)}
        formatOptionLabel={renderOption != null ? (data) => renderOption(data) : undefined}
        components={{
          DropdownIndicator: ({ children, ...props }) => (
            <components.DropdownIndicator {...props}>
              <DropdownIcon up={false} />
              {children}
            </components.DropdownIndicator>
          ),
        }}
        styles={styles}
        options={options}
        classNames={AutocompleteConfig(size, error != null)}
        menuPortalTarget={document.getElementById("root")}
        classNamePrefix="custom-autocomplete"
        isSearchable={searchable}
        noOptionsMessage={() => emptyMessage}
      />
      {error != null && <span className="text-red-500">{error}</span>}
    </div>
  )
}
