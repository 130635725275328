import { ICardTransactionItem, maskCardNumber } from "@usher/pe-client-front-end-lib"
import { TransactionInfo } from "./TransactionInfo"
import { useState } from "react"
import { ClickAwayListener } from "@/Components/ClickAwayListener"

export const getStatusColor = (status: string) => {
  return status === "completed" ||
    status === "sent" ||
    status.includes("Charged") ||
    status.includes("Success")
    ? "text-success"
    : status.toLowerCase().includes("error")
    ? "text-danger"
    : status.includes("Pending") || status.includes("issued")
    ? "text-primary"
    : ""
}

export const TableItem = ({ card }: { card: ICardTransactionItem }) => {
  const [showInfo, setShowInfo] = useState(false)
  return (
    <ClickAwayListener onClickAway={() => setShowInfo(false)}>
      <div className="table-orders_item">
        <div className="table-orders_item-number">#{card.id}</div>
        <div
          className="table-orders_item-logo open-view-hover btn-view-hover"
          onClick={() => setShowInfo(!showInfo)}
          style={{ width: "20%" }}
        >
          {card.transaction}
        </div>
        <div className="table-orders_item-date">{card.date_created}</div>
        <div className="table-orders_item-from-card">
          <span style={{color: Number(card.amount ?? "0") < 0 ? "#ef4444" : '#22c55e'}}>{card.amount}</span>
          {` ${card.currency}`}
        </div>
        <div className="table-orders_item-bank">{maskCardNumber(card.card_pan)}</div>
        {/* <div className="table-orders_item-to-card" style={{ fontWeight: "normal" }}>
        <span style={{ color: isNoFee ? "auto" : "#9a9a9a" }}>
          {isNoFee ? "No Fee" : `${card.fee} ${card.currency}`}
        </span>
      </div> */}
        <div className={`table-orders_item-status table-orders_item-statust`}>{card.status}</div>
        <TransactionInfo
          isAction
          title={card.transaction}
          show={showInfo}
          onView={setShowInfo}
          transactionId={card.id}
        />
      </div>
    </ClickAwayListener>
  )
}
