import { useIntl } from "react-intl"

export const COMPANY_EMAIL = "support@wizarbit.com"
export const COMPANY_PHONE_UK = "+44 2080 971 790"
export const COMPANY_PHONE_LT = "+370 700 310 96"
export const COMPANY_ADDRESS = "A. Vivulskio g. 7-101, Vilnius, LT-03162, Lithuania"
export const LOCATED_AT = "A. Vivulskio g. 7, Vilnius, LT-03162, Lithuania"

export const UPLOAD_STYLES = {
  activeStyle: { background: "#2196f3" },
  acceptStyle: { background: "#78ff73" },
  rejectStyle: { background: "#ff1744" },
}

export const COMPANY_EMAIL_ANCHOR = <a href={`mailto:${COMPANY_EMAIL}`}>{COMPANY_EMAIL}</a>

export const CompanyPhoneNumber = ({ country }: { country?: "UK" | "LT" }) => {
  const intl = useIntl()
  const phone =
    country === "LT"
      ? COMPANY_PHONE_LT
      : country === "UK"
      ? COMPANY_PHONE_UK
      : intl.formatMessage({ id: "phone_number" })
  return <a href={`tel:${phone.replace(/ /g, "")}`}>{phone}</a>
}
