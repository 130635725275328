import { Msg } from "@/Components/Msg"
import { Image } from "@/Components/common/Image"

export const Access = () => {
  return (
    <section className="secure">
      <div className="container">
        <div className="secure__wrap">
          <h2 className="secure__title h2">
            <Msg id="acc1" />
          </h2>
          <ul className="secure-list">
            <li className="secure-list__item">
              <Image src="cards-1.png" />
              <h4 className="secure-list__title">
                <Msg id="acc2" />
              </h4>
              <div className="secure-list__text">
                <Msg id="acc3" />
              </div>
            </li>
            <li className="secure-list__item">
              <Image src="cards-2.png" />
              <h4 className="secure-list__title">
                <Msg id="acc4" />
              </h4>
              <div className="secure-list__text">
                <Msg id="acc5" />
              </div>
            </li>
            <li className="secure-list__item">
              <Image src="cards-3.png" />
              <h4 className="secure-list__title">
                <Msg id="acc6" />
              </h4>
              <div className="secure-list__text">
                <Msg id="acc7" />
              </div>
            </li>
            <li className="secure-list__item">
              <Image src="cards-4.png" />
              <h4 className="secure-list__title">
                <Msg id="acc8" />
              </h4>
              <div className="secure-list__text">
                <Msg id="acc9" />
              </div>
            </li>
            <li className="secure-list__item">
              <Image src="cards-5.png" />
              <h4 className="secure-list__title">
                <Msg id="acc10" />
              </h4>
              <div className="secure-list__text">
                <Msg id="acc11" />
              </div>
            </li>
            <li className="secure-list__item">
              <Image src="cards-6.png" />
              <h4 className="secure-list__title">
                <Msg id="acc12" />
              </h4>
              <div className="secure-list__text">
                <Msg id="acc13" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}
