import { Image } from "@/Components/common/Image"
import { useRef, useState } from "react"

interface IFaqCollapseProps {
  content: string
  title: string
}
export const FaqCollapse = ({ content, title }: IFaqCollapseProps) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const [show, setShow] = useState(false)
  const collapseToggle = () => {
    if (contentRef == null) return
    const result = !show
    contentRef.current.style.maxHeight = (result ? contentRef.current.scrollHeight : 0) + "px"
    setShow(result)
  }
  return (
    <li className="faq-list__item">
      <div className="faq-list__box faq-collapse__toggler" onClick={collapseToggle}>
        <h5 className="faq-list__title">{title}</h5>
        <span className="faq-list__link ">
          <Image src="arrow-link.svg" />
        </span>
      </div>
      <div className="faq-list__text faq-list__collapse-content" ref={contentRef}>
        {content}
      </div>
    </li>
  )
}
