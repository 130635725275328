import "./style.sass"
import { LanguageSelect } from "@/Components/LanguageSelect/index"
import { Button } from "@/Components/Control/Button"
import { toast } from "react-toastify"

export const UI = () => {
  return (
    <div className="ui">
      <Button
        onClick={() => {
          toast.success(
            "Unusual activity detected. Verify your IP. Instructions were sent to your email."
          )
        }}
      >
        SHow error
      </Button>
      <LanguageSelect />
    </div>
  )
}
