import { IOption } from "@/types"
import { RefObject, useMemo } from "react"
import countries from "react-intl-tel-input-18/dist/components/AllCountries"
import { Autocomplete } from "./Autocomplete"

import "./style.sass"
import { useMediaQuery } from "@/hooks"
type CountryPhone = IOption
interface ICountrySelectProps {
  label?: string
  dark?: boolean
  defaultCountry?: string
  firstCountries?: string[]
  error?: string
  value?: string
  onChange?: (country: CountryPhone) => void
  withDialCode?: boolean
  onlyFlag?: boolean
  inputRef?: RefObject<any>
  searchable?: boolean
}
export const CountrySelectComponent = ({
  withDialCode = false,
  error,
  firstCountries = ["gb"],
  defaultCountry = "gb",
  label,
  inputRef,
  value,
  onChange,
  onlyFlag,
  searchable,
}: ICountrySelectProps) => {
  const isMobile = useMediaQuery("(max-width: 768px)")
  const allCountries = useMemo(() => {
    const result = countries.getCountries().reduce<Record<string, CountryPhone>>((acc, country) => {
      Object.assign(acc, {
        [country.iso2 ?? ""]: {
          label: withDialCode ? `${country.name} +${country.dialCode}` : country.name,
          value: `${country.iso2}`,
          meta: {
            iso2: country.iso2,
            dialCode: country.dialCode,
            priority: country.priority ?? 0,
          },
        },
      })
      return acc
    }, {})
    const firsts = firstCountries?.map((first) => result[first.toLowerCase()])
    firstCountries?.forEach((first) => delete result[first])
    return [...(firsts ?? []), ...Object.values(result)]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstCountries])
  const selectedCountry = useMemo(() => {
    return allCountries.find((country) => country.value === (value || defaultCountry))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, defaultCountry])

  return (
    <>
      <Autocomplete
        inputRef={inputRef}
        value={selectedCountry}
        options={allCountries}
        size="md"
        label={label}
        onChange={(value) => {
          onChange?.(value as IOption)
        }}
        styles={
          onlyFlag
            ? {
                singleValue: (base) => ({ ...base, "& span": { display: "none" } }),
                container: (base) => ({ ...base, minWidth: 70, maxWidth: 70 }),
                menu: (base) => ({
                  ...base,
                  width: `${isMobile ? window.screen.width * 0.8 : 480}px !important`,
                }),
              }
            : {
                control: (base) => ({
                  ...base,
                  "&": error != null ? { borderColor: "#dc3545 !important" } : {},
                }),
              }
        }
        renderOption={(option) => (
          <div className="flex items-center gap-2">
            <div className={`iti-flag ${option?.value} min-w-[fit-content]`} />
            <span className="text-xs">{option?.label}</span>
          </div>
        )}
        searchable={searchable}
      />
      {error != null && (
        <span
          style={{
            fontSize: "12px",
            opacity: 0.8,
            lineHeight: "18px",
            fontFamily: "ChakraPetch-Regular",
            color: "#dc3545",
          }}
        >
          {error}
        </span>
      )}
    </>
  )
}

export const CountrySelect = ({
  onlyValue,
  value,
  // error,
  onChange,
  ...props
}: ICountrySelectProps & {
  onlyValue?: boolean
  onChange: (value: CountryPhone) => void
  value: IOption
}) => {
  return (
    <CountrySelectComponent
      defaultCountry=""
      {...props}
      value={onlyValue ? value : (value as IOption)?.value}
      onChange={(x) => {
        if (onlyValue) {
          onChange(x)
        } else {
          onChange(x)
        }
      }}
    />
  )
}
