import React from "react"
import { Route, Switch, useRouteMatch, useParams } from "react-router-dom"
import { useCardView } from "@usher/pe-client-front-end-lib"
import { CardDetails } from "./CardDetails"
import { CardTransactions } from "./Transactions"

export const CardsView = () => {
  const { id } = useParams<{ id: string }>()
  const { path } = useRouteMatch()
  const { card } = useCardView(id)
  if (!card.id) return <div className="lds-dual-ring" />
  return (
    <Switch>
      <Route path={path} exact>
        <CardDetails />
      </Route>
      <Route path={`${path}/transactions`}>
        <CardTransactions />
      </Route>
    </Switch>
  )
}
