import { Msg } from "@/Components/Msg"
import { Image } from "@/Components/common/Image"

export const HowWorks = () => {
  return (
    <section className="work">
      <div className="container">
        <div className="work__wrap">
          <h2 className="h2 work__title">
            <Msg id="how_work" />
          </h2>
          <ul className="work-list">
            <li className="work-list__item">
              <Image src="work-5.png" />
              <h4 className="work-list__title">
                <Msg id="card6" />
              </h4>
              <div className="work-list__text">
                <Msg id="card7" />
              </div>
            </li>
            <li className="work-list__item">
              <Image src="work-6.png" alt="" />
              <h4 className="work-list__title">
                <Msg id="card8" />
              </h4>
              <div className="work-list__text">
                <Msg id="card9" />
              </div>
            </li>
            <li className="work-list__item">
              <Image src="work-7.png" alt="" />
              <h4 className="work-list__title">
                <Msg id="card10" />
              </h4>
              <div className="work-list__text">
                <Msg id="card11" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}
