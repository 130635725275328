import { Image } from "@/Components/common/Image"
import { Button } from "@/Components/Control/Button"
import { scrollTo } from "@/utils"

export const Purch = () => {
  return (
    <section className="purch">
      <div className="container">
        <div className="purch__wrap">
          <h2 className="secure_title h2">Card for everyday purchases</h2>
          <div className="purch__position">
            <Image src="cards-purpl.png" />
            <Image src="cards-black.png" />
          </div>
          <div className="purch__description">
            EMV chip, PIN code and all the specifications provide fast payments, as well as security
            up to the highest world standards.
          </div>
          <Button minWidth={219} variant="primary" onClick={() => scrollTo("sing-up")}>
            Order card
          </Button>
        </div>
      </div>
    </section>
  )
}
