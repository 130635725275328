import "./Base/index.sass"
import React, { CSSProperties, useEffect } from "react"

interface ILayoutProps extends React.HTMLAttributes<HTMLElement> {
  withHeader?: boolean
  onlyLogo?: boolean
  smallHeader?: boolean
  darkTheme?: boolean
  mb?: number
  footerStyle?: CSSProperties
}
export const WidgetLayout = ({ children, mb }: ILayoutProps) => {
  useEffect(() => {
    const html = document.querySelector("html")
    if (html == null) return
    html.style.backgroundColor = "var(--dark)"
    return () => {
      html.style.background = "inherit"
    }
  }, [])
  return (
    <div>
      <main className="main" style={{ paddingBottom: mb || "initial" }}>
        {children}
      </main>
    </div>
  )
}
