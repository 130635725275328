import React, { useEffect } from "react"
import { usePagination, useTable } from "react-table"
import { types, useTransactions } from "@usher/pe-client-front-end-lib"
import { TransactionsFilter } from "./Filter"
import { TransactionsTable } from "./Table"
import { TransactionsPager } from "./Pager"
import "./index.sass"
import { isWidgetPage } from "@/utils"
import { Image } from "@/Components/common/Image"

const getCurrencyLogoUrl = (currency) => {
  if (currency.startsWith("USDT")) return "usdt"
  if (currency.startsWith("USDC")) return "usdc"
  return currency.toLowerCase()
}

const { STATUS_COMPLETED, STATUS_ERROR, STATUS_PENDING } = types.ExTransactionStatus

function renderStatus(status_id, status) {
  switch (parseInt(status_id)) {
    case STATUS_ERROR:
      return <span className="Status Status--Error">{status}</span>
    case STATUS_COMPLETED:
      return <span className="Status Status--Success">{status}</span>
    case STATUS_PENDING:
      return <span className="Status Status--Pending">{status}</span>
    default:
      return <span>{status}</span>
  }
}
function renderStatusSell(status_id, status) {
  switch (parseInt(status_id)) {
    case 5:
    case 11:
      return <span className="Status Status--Error">{status}</span>
    case 3:
    case 10:
      return <span className="Status Status--Success">{status}</span>
    case 1:
      return <span className="Status Status--Pending">{status}</span>
    default:
      return <span>{status}</span>
  }
}
export const PAGE_SIZE = 5

export const Transactions = ({ type = "Buy" }) => {
  const {
    data,
    dataTotal,
    isInitialized,
    // isLoading,
    pageCount: controlledPageCount,
    onFilter,
    setPageIndex,
  } = useTransactions({ type, pageSize: PAGE_SIZE })

  const columns = React.useMemo(() => {
    const cols = [
      { Header: "ID#", accessor: d => <span style={{color: "#777e8a"}}>{`# ${d.id}`}</span> },
      {
        Header: "Date",
        accessor: (d) =>(
          <div className="d-flex align-items-center" style={{gap: "8px"}}>
            <Image style={{width: "20px", height: "20px"}} src={`coins/${getCurrencyLogoUrl(d.crypto_currency)}.svg`} alt={d.crypto_currency} />
            <span>{type}</span>
            <span>{d.crypto_currency_title}</span>
          </div>
        ),
      },
      {
        Header: type === "Buy" ? "Wallet address" : "Wallet to",
        accessor: "address",
        className: "Table__Cell__Break",
      },
      {
        Header: "Exchange to",
        accessor: (d) =>
          type === "Sell"
            ? `${Number(d.fiat_amount).toFixed(2)} EUR`
            : `${Number(d.crypto_amount).toFixed(5)} ${d.crypto_currency}`,
      },
      {
        Header: "Exchange from",
        accessor: (d) =>
          type === "Sell"
            ? <span>{`${Number(d.crypto_amount).toFixed(5)}`} <span style={{color: "#777e8a"}}>{`${d.crypto_currency}`}</span></span>
            : `${Number(d.fiat_amount).toFixed(2)} ${d.fiat_currency}`,
      },
      {
        Header: "Status",
        accessor: (d) =>
          type === "Sell"
            ? renderStatusSell(d.status_id, d.status)
            : renderStatus(d.status_id, d.status),
      },
    ]
    if (type === "Sell")
      cols.splice(3, 0, {
        Header: "Wallet from",
        accessor: (d) => `${d?.wallet_from ?? ""}`,
      })
    return cols
  }, [type])

  const tableData = React.useMemo(() => data, [data])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: tableData,
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination
  )
  useEffect(() => setPageIndex(pageIndex), [pageIndex, setPageIndex])
  return (
    <div className="Transactions">
      {!isWidgetPage() && <TransactionsFilter onFilter={onFilter} type={type} />}
      {isInitialized && (
        <>
          <TransactionsTable
            onFilter={isWidgetPage() ? onFilter : null}
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            prepareRow={prepareRow}
            isShowHeader={false}
            page={page}
            type={type}
          />
          <div style={{paddingLeft: "30px", paddingRight: "30px", paddingBottom: "30px"}}>
            <TransactionsPager
              pageSize={PAGE_SIZE}
              dataTotal={dataTotal}
              pageCount={pageCount}
              pageIndex={pageIndex}
              pageOptions={pageOptions}
              gotoPage={gotoPage}
              nextPage={nextPage}
              canNextPage={canNextPage}
              previousPage={previousPage}
              canPreviousPage={canPreviousPage}
            />
          </div>
        </>
      )}
      {!isInitialized && <p style={{ textAlign: "center" }}>Loading..</p>}
    </div>
  )
}
