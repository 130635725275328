import React from "react"
import ReCaptcha from "react-google-recaptcha"
import { CtrlInput } from "@/Components/ctrl/Input"

type Props = {
  refObj: React.MutableRefObject<never>
  onChange: (value: string) => void
}

export const CtrlReCaptcha = ({ refObj, onChange }: Props) => {
  const siteKey = process.env.REACT_APP_RECAPTCHA
  return (
    <>
      <ReCaptcha ref={refObj} sitekey={siteKey} onChange={onChange} />
      <CtrlInput type="hidden" name="captcha" refProps={{ required: "Please confirm." }} />
    </>
  )
}
