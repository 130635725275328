import { FAQ_DATA } from "./data"
import { Link } from "react-router-dom"
import { FaqCollapse } from "./FaqCollapse"

export const CardFaq = () => {
  return (
    <section className="card__faq">
      <div className="container">
        <div className="faq__wrap">
          <h2 className="h2 faq__title">wizarbit card FAQ</h2>
          <ul className="faq-list">
            {FAQ_DATA.map((faq, index) => (
              <FaqCollapse {...faq} key={index} />
            ))}
          </ul>
          <div style={{ marginTop: 16 }}>
            <h4 className="faq-bottom__title">Have more questions?</h4>
            <div className="faq-bottom__text">
              Find the answers in the Wizarbit <Link to="/contact-us">Help centre</Link> or contact
              our team
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
