import { useExchangeStep3Upload } from "@usher/pe-client-front-end-lib"
import "./style.sass"
import { ReactNode } from "react"
import { Msg } from "@/Components/Msg"
import { HintWrapper } from "../HintWrapper"
import { Input } from "@/Components/Form/Input"

const FileView = ({ file, type }: { file?: File; type: "passport" | "photo" }) => {
  if (file != null)
    return <img src={URL.createObjectURL(file)} className="dropzone__image" alt="" />

  if (type === "passport")
    return (
      <span className="upload_hint">
        <Msg id="dv12" />
      </span>
    )
  return (
    <span className="upload_hint">
      <Msg id="dv11" />
    </span>
  )
}

interface IExchangeDocumentyUploadProps {
  marginBottom?: number
  type: "photo" | "passport"
  tooltip: ReactNode
  file?: File
  setFile: (file: File) => void
}

export const ExchangeDocumentUpload = ({
  marginBottom,
  setFile,
  type,
  file,
  tooltip,
}: IExchangeDocumentyUploadProps) => {
  const { getInputProps, getRootProps, style } = useExchangeStep3Upload({
    file,
    setFile,
    rejectStyle: { background: "#DECEFB" },
    acceptStyle: { background: "green" },
    activeStyle: { background: "blue" },
  })
  return (
    <div style={{ marginBottom }}>
      <div {...getRootProps({ className: "document__upload", style })}>
        <FileView {...{ file, type }} />
        <input type="file" {...getInputProps({ name: type })} />
        <div className="upload__bottom">
          <span className="document__upload-span">
            <span>Drag file here or</span>
            <span>{" select a file "}</span>
            <span>to upload</span>
          </span>
          <HintWrapper content={tooltip} />
        </div>
      </div>
      <Input label="" type="hidden" name={`${type}_file`} value={file ? "1" : ""} required />
    </div>
  )
}
