import { Modal } from "@/Components/Modal"
import { Button } from "@/Components/Control/Button"
import { useExchangeStep1Verify } from "@usher/pe-client-front-end-lib"

interface IExchangeVerifyModalProps {
  onHide: () => void
  onVerified: () => void
}

export const ExchangeVerifyModal = ({ onHide, onVerified }: IExchangeVerifyModalProps) => {
  const email = useExchangeStep1Verify(onVerified)
  return (
    <Modal size={1} isOpen handleClose={onHide}>
      <div className="Modal__Title">We&apos;ve sent you a verification e-mail</div>
      <div className="Modal__Description">
        E-mail with the confirmation URL was sent to <a href={`mailto:${email}`}>{email}</a>. Visit
        the URL to be redirected back to Wizarbit.
      </div>
      <div className="Modal__Button">
        <a href={`//${email}`}>
          <Button style={{ padding: "0 12px !important", minWidth: "100%" }}>
            Confirm your e-mail, if you wish to continue
          </Button>
        </a>
      </div>
    </Modal>
  )
}
