import React, { ImgHTMLAttributes } from "react"

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  src: string
  alt?: string
  className?: string
}

export const Image = ({ src, alt = "", className, ...rest }: ImageProps) => (
  <img src={`/img/${src}`} alt={alt} className={className} {...rest} />
)
