import { Image } from "@/Components/common/Image"
import { ThemeMode } from "@/types"

interface IPhonesProps {
  themeMode: ThemeMode
}
export const Phones = ({ themeMode }: IPhonesProps) => {
  const isDark = themeMode === ThemeMode.Dark
  return (
    <div className={`footer__phones ${!isDark ? "footer__phone-light" : ""}`}>
      <div className="footer__phone">
        <Image src="footer-phone1.svg" />
        <a href="tel:+370 700 310 96">+44 2080 971 790</a>
      </div>
      <div className="footer__phone">
        <Image src="footer-phone2.svg" />
        <a href="tel:+370 700 310 96">+370 700 310 96</a>
      </div>
    </div>
  )
}
