import { Dialog } from "@/Components/Modal"
import warningIcon from "@/Images/cards_images/warning.svg"

interface IInstructionDialogProps {
  showDialog: boolean
  onLeave: VoidFunction
  onCancel: VoidFunction
}

export const InstructionDialog = ({ showDialog, onCancel, onLeave }: IInstructionDialogProps) => {
  return (
    <Dialog
      closable={false}
      isOpen={Boolean(showDialog)}
      handleClose={onCancel}
      title="Do you want to leave the page?"
      size={1}
    >
      <>
        <div className="warning-info" style={{ fontSize: 14 }}>
          <img src={warningIcon} alt="" />
          <span>
            Attention: If you have paid but have not confirmed the payment by clicking
            <br /> the <strong>I have paid</strong> button, the transaction will not be created.
          </span>
        </div>
        <div style={{ textAlign: "right", marginTop: 20 }}>
          <button
            style={{ marginRight: 12 }}
            className="btn-reset btn-sq btn-blue-dark"
            color="Blue"
            onClick={onCancel}
          >
            Go back and confirm
          </button>
          <button className="btn-reset btn-sq btn-red" color="Blue" onClick={onLeave}>
            Leave Page
          </button>
        </div>
      </>
    </Dialog>
  )
}
