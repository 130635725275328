import { Image } from "@/Components/common/Image"
import { ArrowButton } from "@/Components/Control/Button"
import { Msg } from "@/Components/Msg"
import { Container } from "@/Layout/Container"
import "./style.css"

export const OurCards = () => {
  return (
    <section className="home_section cards">
      <Container>
        <h2 className="h2 cards__h2">
          <Msg id="our_cards" />
        </h2>
        <div className="cards__row">
          <div className="card">
            <Image src="card-1.png" alt="Card" />
            <div className="card__text">
              <div>
                <h3 className="card__title">
                  <Msg id="physical" />
                </h3>
                <p className="card__describe">
                  <Msg id="card3" />
                </p>
              </div>
              <ArrowButton title={<Msg id="getacard" />} to="/card" />
            </div>
          </div>
          <div className="card">
            <Image src="card-2.png" alt="Card" />
            <div className="card__text">
              <div>
                <h3 className="card__title">
                  <Msg id="virtual" />
                </h3>
                <p className="card__describe">
                  <Msg id="linkyour" />
                </p>
              </div>
              <ArrowButton title={<Msg id="getacard" />} to="/card" />
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}
